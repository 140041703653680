import * as types from "../ActionType";
import axios from "axios";
import { notifyError, notifysuccess,notifyErrorNetWork } from "../../Components/General/Notification";
import { base_url } from "../../Components/General/Notification";
import moment from 'moment';

const get = (arr,type) => {
    return ({
        type: type,
        payload: arr
    })
}
const getStatistics = (statistics) => {
    return ({
        type: types.GET_ALL_STATISTICS,
        payload: statistics
    })
}
const emptyGeneral = (empty,typee) => {
    return ({
        type: typee,
        payload: empty
    })
}

const getGeneralStatistics = (generalStatic) => {
    return ({
        type: types.GET_GENERAL_STATIC,
        payload: generalStatic
    })
}

export const loadStatistics = (id) => {
    return function (dispatch) {
        let token = localStorage.getItem("tokenMiamed");
        axios.get(`${base_url}api/work-plan/${id}/visits/statistics`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }).then((res) => {
            dispatch(getStatistics(res.data.data))
        }).catch((err) => {
            /*if(err.message == "Network Error" || err.message){
                notifyErrorNetWork("An error occurred. Please reload the page")
            }*/
        })
    }
}

export const loadGeneralStatistics = () => {
    return function (dispatch) {
        let token = localStorage.getItem("tokenMiamed");
        axios.get(`${base_url}api/dashboard/statistics`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }).then((res) => {
            dispatch(getGeneralStatistics(res.data.data))
        }).catch((err) => {
            /*if(err.message == "Network Error" || err.message){
                notifyErrorNetWork("An error occurred. Please reload the page")
            }*/
        })
    }
}

export const emptyStatistics = () => {
    return function (dispatch) {
        let emptyy = []
        dispatch(emptyGeneral(emptyy , types.EMPTY_ALL_STATISTICS ))
    }
}

export const loadStatisticsWorkPlan = () => {
    return function (dispatch) {
        let token = localStorage.getItem("tokenMiamed");
        axios.get(`${base_url}api/medical-rep/work-plan/general/statistics`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }).then((res) => {
            dispatch(get(res.data.data,types.GET_STATISTICS_WORK_PLANS))
        }).catch((err) => {
        })
    }
}

export const loadStatisticExternal = () => {
    return function (dispatch) {
        let token = localStorage.getItem("tokenMiamed");
        axios.get(`${base_url}api/medical-rep/external-visits/general/statistics`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }).then((res) => {
            dispatch(get(res.data.data,types.GET_STATISTICS_EXTERNAL_VISITE))
        }).catch((err) => {
        })
    }
}

export const loadStatisticTarget = () => {
    return function (dispatch) {
        let token = localStorage.getItem("tokenMiamed");
        axios.get(`${base_url}api/medical-rep/target-group-visits/general/statistics`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }).then((res) => {
            dispatch(get(res.data.data,types.GET_STATISTICS_TARGET_GROUP))
        }).catch((err) => {
        })
    }
}


export const FilterStatisticsWorkPlan = (start_date, end_date, target_group_type_id,report_type_id, selectedOptions, setLoad) => {
    return function (dispatch) {
        setLoad(true)
        let token = localStorage.getItem("tokenMiamed");
        const termsQuery = selectedOptions.map((id, index) => `filter[medicalRepIds][${index}]=${id}`).join('&');
        if (start_date !== '' && end_date !== '') {
            start_date = moment(start_date).format('YYYY-MM-DD')
            end_date = moment(end_date).format('YYYY-MM-DD')
        }
        axios.get(`${base_url}api/medical-rep/work-plan/general/statistics?filter[start_date]=${start_date}&filter[end_date]=${end_date}&filter[target_group_type_id]=${target_group_type_id}&filter[report_type_id]=${report_type_id}&${termsQuery}&filter[all]=${true}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }).then((res) => {
            dispatch(get(res.data.data,types.GET_STATISTICS_WORK_PLANS))
            setLoad(false)
        }).catch((err) => {
            setLoad(false)
        })
    }
}



export const FilterStatisticTarget = (start_date, end_date, target_group_type_id,report_type_id, selectedOptions, setLoad) => {
    return function (dispatch) {
        setLoad(true)
        let token = localStorage.getItem("tokenMiamed");
        const termsQuery = selectedOptions.map((id, index) => `filter[medicalRepIds][${index}]=${id}`).join('&');
        if (start_date !== '' && end_date !== '') {
            start_date = moment(start_date).format('YYYY-MM-DD')
            end_date = moment(end_date).format('YYYY-MM-DD')
        }
        axios.get(`${base_url}api/medical-rep/target-group-visits/general/statistics?filter[start_date]=${start_date}&filter[end_date]=${end_date}&filter[target_group_type_id]=${target_group_type_id}&filter[report_type_id]=${report_type_id}&${termsQuery}&filter[all]=${true}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }).then((res) => {
            dispatch(get(res.data.data,types.GET_STATISTICS_TARGET_GROUP))
            setLoad(false)
        }).catch((err) => {
            setLoad(false)
        })
    }
}


// new statistic

// export const FilterStatisticTargetWithMidicalRep = (selectedOptions, setLoad) => {
//     return function (dispatch) {
//         setLoad(true)
//         let token = localStorage.getItem("tokenMiamed");
//         const termsQuery = selectedOptions.map((id, index) => `filter[medicalRepIds][${index}]=${id}`).join('&');
//         axios.get(`${base_url}api/medical-rep/work-plan/general/statistics?filter[medical_rep_id]=&${termsQuery}`, {
//             headers: {
//                 'Authorization': `Bearer ${token}`
//             }
//         }).then((res) => {
//             dispatch(get(res.data.data,types.GET_STATISTICS_TARGET_GROUP_WITH_MEDICAL_REP))
//             setLoad(false)
//         }).catch((err) => {
//             setLoad(false)
//         })
//     }
// }



export const FilterStatisticTargetWithMidicalRep = (
    selectedIds,
    setLoad
  ) => {
    return function (dispatch) {
      setLoad(true);
      let token = localStorage.getItem("tokenMiamed");
      const medicalRepIdsQuery = selectedIds === null ? '' :selectedIds ;
  
      axios
        .get(
          `${base_url}api/medical-rep/work-plan/general/statistics?filter[medical_rep_id]=${medicalRepIdsQuery}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
         
            dispatch(get(res.data.data,types.GET_STATISTICS_TARGET_GROUP_WITH_MEDICAL_REP))
setLoad(false);
        })
        .catch((err) => {
          setLoad(false);
        });
    };
  };


export const FilterStatisticExternalnew = (start_date, end_date, target_group_type_id,report_type_id, selectedOptions, setLoad) => {
    return function (dispatch) {
        setLoad(true)
        let token = localStorage.getItem("tokenMiamed");
        const termsQuery = selectedOptions.map((id, index) => `filter[medicalRepIds][${index}]=${id}`).join('&');
        if (start_date !== '' && end_date !== '') {
            start_date = moment(start_date).format('YYYY-MM-DD')
            end_date = moment(end_date).format('YYYY-MM-DD')
        }
        axios.get(`${base_url}api/medical-rep/external-visits/general/statistics?filter[start_date]=${start_date}&filter[end_date]=${end_date}&filter[target_group_type_id]=${target_group_type_id}&filter[report_type_id]=${report_type_id}&${termsQuery}&filter[all]=${true}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }).then((res) => {
            dispatch(get(res.data.data,types.GET_STATISTICS_EXTERNAL_VISITE))
            setLoad(false)
        }).catch((err) => {
            setLoad(false)
        })
    }
}
import { useSelector } from "react-redux";

export const useSelectorCustom = () => {
  const target_types = useSelector((state) => state.target.target_types);
  const loadingtarget_type = useSelector(
    (state) => state.target.loadingtarget_type
  );
  const medicalRep = useSelector((state) => state.medical_rep.medicalRep);
  const loadingmedicalRep = useSelector(
    (state) => state.medical_rep.loadingmedicalRep
  );
  const reportType = useSelector((state) => state.report_type.reportType);
  const loadingReportType = useSelector(
    (state) => state.report_type.loadingReportType
  );
  const generalReport = useSelector((state) => state.reportss.generalReport);

  const loadinggeneralReport = useSelector(
    (state) => state.reportss.loadinggeneralReport
  );

  const generalExReport = useSelector(
    (state) => state.reportss.generalExReport
  );
  const loadinggeneralExReport = useSelector(
    (state) => state.reportss.loadinggeneralExReport
  );
  const reportOrdersReqs = useSelector(
    (state) => state.reportss.reportOrdersReqs
  );
  const loagingreportOrdersReqs = useSelector(
    (state) => state.reportss.loagingreportOrdersReqs
  );
  const objections = useSelector((state) => state.reportss.objections);
  const loagingobjections = useSelector(
    (state) => state.reportss.loagingobjections
  );
  const orders = useSelector((state) => state.reportss.orders);
  const loagingorders = useSelector((state) => state.reportss.loagingorders);
  const targetGroupsVisites = useSelector(
    (state) => state.reportss.targetGroupsVisites
  );
  const loagingTargetGroupsVisites = useSelector(
    (state) => state.reportss.loagingTargetGroupsVisites
  );
  const targetGroupsVisitesWithDate = useSelector(
    (state) => state.reportss.targetGroupsVisitesWithDate
  );
  const loagingTargetGroupsVisitesWithDate = useSelector(
    (state) => state.reportss.loagingTargetGroupsVisitesWithDate
  );
  const allVisits = useSelector((state) => state.reportss.allVisits);
  const loagingAllVisits = useSelector(
    (state) => state.reportss.loagingAllVisits
  );
  const freeSamples = useSelector((state) => state.reportss.freeSamples);
  const loagingFreeSamples = useSelector(
    (state) => state.reportss.loagingFreeSamples
  );
  const freeSamplesTarget = useSelector(
    (state) => state.reportss.freeSamplesTarget
  );
  const loagingFreeSamplesTarget = useSelector(
    (state) => state.reportss.loagingFreeSamplesTarget
  );
  const marketingTools = useSelector((state) => state.reportss.marketingTools);
  const loagingMarketingTools = useSelector(
    (state) => state.reportss.loagingMarketingTools
  );
  const monthlyProductsSales = useSelector(
    (state) => state.reportss.monthlyProductsSales
  );
  const loagingMonthlyProductsSales = useSelector(
    (state) => state.reportss.loagingMonthlyProductsSales
  );
  const medicalRepTarget = useSelector(
    (state) => state.reportss.medicalRepTarget
  );
  const loagingMedicalRepTarget = useSelector(
    (state) => state.reportss.loagingMedicalRepTarget
  );
  const hospital = useSelector((state) => state.reportss.hospital);
  const loaginghospital = useSelector(
    (state) => state.reportss.loaginghospital
  );
  const RepSales = useSelector((state) => state.reportss.RepSales);
  const loagingRepSales = useSelector(
    (state) => state.reportss.loagingRepSales
  );

  const reportTargetGroup = useSelector(
    (state) => state.reportss.reportTargetGroup
  );
  const loagingreportTargetGroup = useSelector(
    (state) => state.reportss.loagingreportTargetGroup
  );
  const notify = useSelector((state) => state.auth.notify);
  const loadingNotify = useSelector((state) => state.auth.loadingNotify);
  const cities = useSelector((state) => state.countries.cities);
  const loadingCities = useSelector((state) => state.countries.loadingCities);
  const governorates = useSelector((state) => state.governorates.governorates);
  const loadinGovernorate = useSelector(
    (state) => state.governorates.loadinGovernorate
  );
  const specializations = useSelector((state) => state.target.specializations);
  const loadingspecializations = useSelector(
    (state) => state.target.loadingspecializations
  );
  const TGbymedicalRep = useSelector(
    (state) => state.medical_rep.TGbymedicalRep
  );
  const loadingTG = useSelector((state) => state.medical_rep.loadingTG);
  const target_group = useSelector((state) => state.target.target_group);
  const loadingTargetGroup = useSelector(
    (state) => state.target.loadingTargetGroup
  );
  const countriess = useSelector((state) => state.countries.countriess);
  const loadingCountries = useSelector(
    (state) => state.countries.loadingCountries
  );
  const senior_supervisor = useSelector(
    (state) => state.auth.senior_supervisor
  );
  const loadingsenior_supervisor = useSelector(
    (state) => state.auth.loadingsenior_supervisor
  );
  const workflow = useSelector((state) => state.work_floww.workflow);
  const loadingWorkflow = useSelector(
    (state) => state.work_floww.loadingWorkflow
  );
  const product = useSelector((state) => state.productt.product);
  const loadingproduct = useSelector((state) => state.productt.loadingproduct);
  const measurement = useSelector((state) => state.configProduct.measurement);
  const loadingmeasurement = useSelector(
    (state) => state.configProduct.loadingmeasurement
  );
  const productCat = useSelector((state) => state.configProduct.productCat);
  const loadingproductCat = useSelector(
    (state) => state.configProduct.loadingproductCat
  );
  const oneWorkflow = useSelector((state) => state.work_floww.oneWorkflow);
  const loadingoneWorkflow = useSelector(
    (state) => state.work_floww.loadingoneWorkflow
  );
  const external_visites = useSelector(
    (state) => state.externalVisites.external_visites
  );
  const loadingexternal_visites = useSelector(
    (state) => state.externalVisites.loadingexternal_visites
  );
  const Vacation = useSelector((state) => state?.vacationn?.Vacation);
  const loadingVacation = useSelector(
    (state) => state.vacationn.loadingVacation
  );
  const roles = useSelector((state) => state.roless.roles);
  const loadingRoles = useSelector((state) => state.roless.loadingRoles);
  const permission = useSelector((state) => state.roless.permission);
  const loadingpermission = useSelector(
    (state) => state.roless.loadingpermission
  );
  const classifications = useSelector((state) => state.target.classifications);
  const loadingclassifications = useSelector(
    (state) => state.target.loadingclassifications
  );
  const users = useSelector((state) => state.auth.users);
  const lasts = useSelector((state) => state.auth.lasts);
  const loadingUsers = useSelector((state) => state.auth.loadingUsers);
  const loadingLasts = useSelector((state) => state.auth.loadingLasts);
  const requests = useSelector((state) => state.request.requests);
  const loadingRequests = useSelector((state) => state.request.loadingRequests);
  const politics = useSelector((state) => state.politicss.politics);
  const loadingpolitics = useSelector(
    (state) => state.politicss.loadingpolitics
  );

  const StockProduct = useSelector((state) => state.reportss.StockProduct);
  const loagingStockProducts = useSelector(
    (state) => state.reportss.loagingStockProducts
  );
  const StockMarkiting = useSelector((state) => state.reportss.StockMarkiting);
  const loagingStockMarkitings = useSelector(
    (state) => state.reportss.loagingStockMarkitings
  );

  return {
    users,
    loadingUsers,

    StockProduct,
    loagingStockProducts,
    StockMarkiting,
    loagingStockMarkitings,

    target_types,
    loadingtarget_type,
    medicalRep,
    loadingmedicalRep,
    reportType,
    loadingReportType,
    generalReport,
    loadinggeneralReport,
    generalExReport,
    loadinggeneralExReport,
    reportOrdersReqs,
    loagingreportOrdersReqs,
    objections,
    loagingobjections,
    orders,
    loagingorders,
    targetGroupsVisites,
    loagingTargetGroupsVisites,
    targetGroupsVisitesWithDate,
    loagingTargetGroupsVisitesWithDate,
    allVisits,
    loagingAllVisits,
    freeSamples,
    loagingFreeSamples,
    freeSamplesTarget,
    loagingFreeSamplesTarget,
    marketingTools,
    loagingMarketingTools,
    monthlyProductsSales,
    loagingMonthlyProductsSales,
    medicalRepTarget,
    loagingMedicalRepTarget,
    //
    hospital,
    loaginghospital,
    //
    RepSales,
    loagingRepSales,
    reportTargetGroup,
    loagingreportTargetGroup,
    notify,
    loadingNotify,
    cities,
    loadingCities,
    governorates,
    loadinGovernorate,
    specializations,
    loadingspecializations,
    TGbymedicalRep,
    loadingTG,
    target_group,
    loadingTargetGroup,
    countriess,
    loadingCountries,
    senior_supervisor,
    loadingsenior_supervisor,
    workflow,
    loadingWorkflow,
    product,
    loadingproduct,
    measurement,
    loadingmeasurement,
    productCat,
    loadingproductCat,
    oneWorkflow,
    loadingoneWorkflow,
    external_visites,
    loadingexternal_visites,
    Vacation,
    loadingVacation,
    roles,
    loadingRoles,
    permission,
    loadingpermission,
    classifications,
    loadingclassifications,

    lasts,
    loadingLasts,
    requests,
    loadingRequests,
    politics,
    loadingpolitics,
  };
};

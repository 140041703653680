import React, { useEffect, useState } from "react";
import { loadLast } from "../../Redux/Actions/ActionAuth";
import { AbstructLang } from "../General/GeneralComponent/AbstructLang";
import { AbstructVar } from "../General/GeneralComponent/AbstructVar";
import { useSelectorCustom } from "../General/GeneralComponent/useSelectorCustom";
import InnerLoader from "../General/InnerLoader";
import NoInternet from "../General/NotPermission/NoInternet";
import Page_Header from "../General/Page_Header";
import Table2 from "../Table2/Table2";
import "./Last.css";
import { ColumnLast, VariableLast } from "./VariableLast";

function Index() {
  const [openInternet, setopenInternet] = useState(false);
  let { dispatch, navigate, langmiamed } = AbstructVar();
  
  const { lasts, loadingLasts } = useSelectorCustom(); // Make sure to use correct loading state
  const reversedLast = Array.isArray(lasts) ? [...lasts].reverse() : [];
  const { translate } = AbstructLang();
  const { state } = VariableLast();

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(loadLast(navigate, setopenInternet)); // Load the last report on mount
  }, [dispatch, navigate]);

  const { columnsLast } = ColumnLast(translate);

  return (
    <>
      <div className={langmiamed === "ar" ? "main-content_ar" : "main-content_en"}>
        <main>
          <Page_Header
            langmiamed={langmiamed}
            header={translate("last")}
            path={`${translate("home")} / ${translate("last")}`}
          />
        </main>
        <div className="page-content">
          <div className="users" dir={langmiamed === "ar" ? "rtl" : "ltr"}>
            {!loadingLasts ? ( // Corrected to use loadingLasts state
              <Table2 col={columnsLast} roo={reversedLast} />
            ) : (
              <InnerLoader />
            )}
          </div>
        </div>
      </div>
      {openInternet && (
        <NoInternet open={openInternet} setOpen={setopenInternet} />
      )}
    </>
  );
}

export default Index;

import Login from "../Auth/Login";
import Main from "../Main/Main";
import TargetGroup from "../TargetGroup/TargetGroup";
import Products from "../Products/Products";
import Delegates from "../Delegates/Delegates";
import Vacation from "../Vacation/Vacation";
import External_Visites from "../External_Visites/External_Visites";
import Country from "../Country/Country";
import WorkPlans from "../WorkPlans/WorkPlans";
import WorkTask from "../WorkPlans/WorkTask/WorkTask";
import Settings from "../Settings/Settings";
import Users from "../Users/Users";
import WorkFlow from "../WorkFlow/WorkFlow";
import Roles from "../RolesAndPermissions/Roles/Roles";
import Permissions from "../RolesAndPermissions/Permissions/Permissions";
import Assign from "../Assign/Assign";
import GeneralReports from "../GeneralReports/GeneralReports";
import DetailsTask from "../WorkPlans/WorkTask/DetailsTask/DetailsTask";
import NotPermission from "./NotPermission/NotPermission";
import NotFound from "./NotPermission/NotFound";
import OutNotFound from "./OutNotFound";
import VisitsByMedRep from "../Delegates/VisitsByMedRep/VisitsByMedRep";
import Politics from "../Politics/Politics";
import PageNotification from "../Main/PageNotification/PageNotification";
import Request from "../Request/Request";
import AllRequests from "../Request/AllRequests";
import TargetOrders from "../TargetOrders/TargetOrders.jsx";
import MarketingTools from "../marketingTools/MarketingTools.js";


import Index from "../LastLogin/Index.jsx"
const useRout = (token) => {
  const routes = [
    {
      path: "/",
      element: token == null ? <Login /> : <Main isDashboardPage={true} />,
    },
    {
      path: "/target-group",
      element: token == null ? <OutNotFound /> : <TargetGroup />,
    },
    {
      path: "/last-login",
      element: token == null ? <OutNotFound /> : <Index />,
    },
    {
      path: "/product",
      element: token == null ? <OutNotFound /> : <Products />,
    },
    {
      path: "/marketing-tools",
      element: token == null ? <OutNotFound /> : <MarketingTools />,
    },
    {
      path: "/medical-representative",
      element: token == null ? <OutNotFound /> : <Delegates />,
    },
    {
      path: "/medical-representative/:id/:name",
      element: token == null ? <OutNotFound /> : <VisitsByMedRep />,
    },
    {
      path: "/vacation",
      element: token == null ? <OutNotFound /> : <Vacation />,
    },
    {
      path: "/country",
      element: token == null ? <OutNotFound /> : <Country />,
    },
    {
      path: "/external-visites",
      element: token == null ? <OutNotFound /> : <External_Visites />,
    },
    {
      path: "/work-plans",
      element: token == null ? <OutNotFound /> : <WorkPlans />,
    },
    {
      path: "/work-plans/:id/task",
      element: token == null ? <OutNotFound /> : <WorkTask />,
    },
    {
      path: "/work-plans/:id/task/:id_task",
      element: token == null ? <OutNotFound /> : <DetailsTask />,
    },
    {
      path: "/settings",
      element: token == null ? <OutNotFound /> : <Settings />,
    },
    { path: "/users", element: token == null ? <OutNotFound /> : <Users /> },
    // {
    //   path: "/work-flow",
    //   element: token == null ? <OutNotFound /> : <WorkFlow />,
    // },
    { path: "/roles", element: token == null ? <OutNotFound /> : <Roles /> },
    { path: "/assign", element: token == null ? <OutNotFound /> : <Assign /> },
    {
      path: "/page404",
      element: token == null ? <OutNotFound /> : <NotPermission />,
    },
    {
      path: "/roles/:id/:name/permission",
      element: token == null ? <OutNotFound /> : <Permissions />,
    },
    {
      path: "/notifications",
      element: token == null ? <OutNotFound /> : <PageNotification />,
    },
    {
      path: "/reports",
      element: token == null ? <OutNotFound /> : <GeneralReports />,
    },
    {
      path: "/request",
      element: token == null ? <OutNotFound /> : <Request />,
    },
    {
      /* path: "/all-requests", element: token == null ?<OutNotFound />: <AllRequests /> */
    },
    {
      path: "/politics",
      element: token == null ? <OutNotFound /> : <Politics />,
    },
    {
      path: "/targetorders",
      element: token == null ? <OutNotFound /> : <TargetOrders />,
    },
    {
      path: "/marketingTools",
      element: token == null ? <OutNotFound /> : <MarketingTools />,
    },
    { path: "*", element: <NotFound /> },
  ];
  return routes;
};

export default useRout;

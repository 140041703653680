import React from 'react';
import ReactDOM from 'react-dom';
//import 'bootstrap/dist/css/bootstrap.css'
import App from './App';
import './index.css'
import './i18n'
//import 'mdb-react-ui-kit/dist/css/mdb.min.css'
import { BrowserRouter } from "react-router-dom"
import store from './Redux/Store';
import { Provider } from "react-redux";
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

ReactDOM.render(
    <BrowserRouter>
      <Provider store={store}>
        <App />
      </Provider>
    </BrowserRouter>
 ,
  document.getElementById('root')
);

serviceWorkerRegistration.register();



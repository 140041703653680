import React from "react";
import Table2 from "../Table2/Table2";
import select from "../../images/icons/pointer.png";
import External_In_Map from "../External_Visites/External_In_Map";
import FilterStatistics from "../Main/Statistics/FilterStatistics";
import { useSelectorCustom } from "../General/GeneralComponent/useSelectorCustom";
import { ColumnArrayMonthlyOrders, ColumnArrayMonthlyProductsSales, ColumnArrayReportGetMarkitingsStock, ColumnArrayReportGetProductsStock, VariableReports } from "./VariableReports";

function StockMarkiting({ langmiamed, t, navigate, setopenInternet }) {
  const { StockMarkiting, loagingStockMarkitings } =
    useSelectorCustom();
  const reversedgeneralExReport = StockMarkiting?.reverse();
  const { state, storeMap, setIsOpen } = VariableReports();
  const { MarkitingStockReqsColumn } = ColumnArrayReportGetMarkitingsStock(t, storeMap);
  return (
    <>
      <FilterStatistics
        t={t}
        langmiamed={langmiamed}
        params="get_markitings_stock_report"
        excel="get_markitings_stock_report"
        
      />
      <div className="users" dir={langmiamed == "ar" ? "ltr" : "ltr"}>
        {!loagingStockMarkitings  ? (
          <Table2 col={MarkitingStockReqsColumn} roo={reversedgeneralExReport} />
        ) : (
          <div className="alternative">
            <img src={select} />
            <br></br>
            <p>{t("filterr")}</p>
          </div>
        )}
      </div>
      {state.openMap && (
        <External_In_Map
          open={state.openMap}
          setOpen={setIsOpen}
          lat={state.lat}
          lng={state.lng}
        />
      )}
    </>
  );
}

export default StockMarkiting;

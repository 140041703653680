import React, { useEffect, useState } from "react";
import logo from "../../images/logo_miamed.png";
import logo1 from "../../images/logo_miamed1.png";
import flower from "../../images/favicon.png";
import { Link, useLocation } from "react-router-dom";
import { sidebar_choise } from "../../dummyData";
import { UsePermissions, hasPermissions } from "../General/UsePermissions";
import NoInternet from "../General/NotPermission/NoInternet";
import { messaging } from "../../firebase";
import { SendFCM } from "../../Redux/Actions/ActionAuth";
import { AbstructLang } from "../General/GeneralComponent/AbstructLang";
import { AbstructVar } from "../General/GeneralComponent/AbstructVar";

function Sidebar() {
  let { dispatch, langmiamed } = AbstructVar();
  const [openInternet, setopenInternet] = useState(false);
  let FCM = localStorage.getItem("exitFCM");
  const { translate } = AbstructLang();
  const location = useLocation();
  const [activeId, setActiveId] = useState(1);
  const permissions1 = UsePermissions(setopenInternet);

  useEffect(() => {
    const activeItem = sidebar_choise.find(
      (item) => item.link === location.pathname
    );
    if (activeItem) {
      setActiveId(activeItem.id);
    }
  }, [location.pathname]);

  const dashboard = hasPermissions([{ name: "dashborad" }], permissions1);

  const target_Groups = hasPermissions(
    [{ name: "target-Groups" }],
    permissions1
  );
  const products = hasPermissions([{ name: "products" }], permissions1);
  const users = hasPermissions([{ name: "users" }], permissions1);
  // const work_flows = hasPermissions([{ name: "work-flows" }], permissions1);
  const medical_representatives = hasPermissions(
    [{ name: "medical-representatives" }],
    permissions1
  );
  const medical_representatives_info = hasPermissions(
    [{ name: "medical-representatives-info" }],
    permissions1
  );
  const assign_medical_representatives = hasPermissions(
    [{ name: "assign-medical-representatives" }],
    permissions1
  );
  const work_plans = hasPermissions([{ name: "work-plans" }], permissions1);
  const reports = hasPermissions([{ name: "reports" }], permissions1);
  const vacations = hasPermissions([{ name: "vacations" }], permissions1);
  const external_visit = hasPermissions(
    [{ name: "external-visit" }],
    permissions1
  );
  const regions = hasPermissions([{ name: "regions" }], permissions1);
  const roles = hasPermissions([{ name: "roles" }], permissions1);
  // const settings = hasPermissions([{ name: "settings" }], permissions1);
  const targetorders = hasPermissions([{ name: "targetorders" }], permissions1);
  const marketingTools = hasPermissions([{ name: "marketingTools" }], permissions1);

// new tabs
   const lastLoginUsers = hasPermissions([{ name: "lastLoginUsers" }], permissions1);

  const filteredOptions = sidebar_choise.filter((ss) => {
    if (ss.name == "Last Login" ) return true;

    if (ss.name == "Dashboard") return true;
    if (ss.name == "Target Information" && target_Groups) return true;
    if (ss.name == "Products" && products) return true;
    if (ss.name == "Users" && users) return true;
    // if (ss.name == "Work Flow" && work_flows) return true;
    if (ss.name == "Medical Representative" && medical_representatives)
      return true;
    if (ss.name == "Assign" && assign_medical_representatives) return true;
    if (ss.name == "Work Plans" && work_plans) return true;
    if (ss.name == "Reports" && reports) return true;
    if (ss.name == "Vacations" && vacations) return true;
    if (ss.name == "Request") return true;
    if (ss.name == "Other Task" && external_visit) return true;
    if (ss.name == "Country And City" && regions) return true;
    if (ss.name == "Roles" && roles) return true;
    if (ss.name == "Politics") return true;
    // if (ss.name == "Settings" && settings) return true;
    if (ss.name == "Target Orders" && targetorders) return true;
    if (ss.name == "Marketing Tools" && marketingTools) return true;

    // const lastLoginUsers = hasPermissions([{ name: "lastLoginUsers" }], permissions1);

    return false;
  });

  function subscribeTokenToTopic(token, topic) {
    fetch(`https://iid.googleapis.com/iid/v1/${token}/rel/topics/${topic}`, {
      method: "POST",
      headers: new Headers({
        authorization: `key=AAAA-FF9gFg:APA91bHzhsnzPFmUDOKVoV70tJcn6Wsb4mInxzbrcRKmm2vvTgZkuPfx6f3MPpyzjSmPR0p0ly7x16UxOd4LdPeOm-LJLhy6JmAMBtUfPu6aidQMyyOm6aWOgXrccMZQyf6BfPCLBMPX`,
      }),
    })
      .then((response) => {
        if (response.status < 200 || response.status >= 400) {
        }
      })
      .catch((error) => {
        console.error(error.result);
      });
    return true;
  }
  const registerDeviceForNotifications = async () => {
    try {
      const permission = await Notification.requestPermission();
      if (permission === "granted") {
        const token = await messaging.getToken();
        if (token) {
          subscribeTokenToTopic(token, "control");
          dispatch(SendFCM(token));
        } else {
        }
      } else {
      }
    } catch (error) {
    }
  };

  useEffect(() => {
    if (FCM == "FCM") {
      localStorage.setItem("exitFCM", "");
      registerDeviceForNotifications();
    }
  }, []);
  return (
    <>
      <div className={langmiamed === "ar" ? "sidebar_ar" : "sidebar_en"}>
        <div className="side-header">
          <img className="allname" src={logo1} alt="Logo" />
          <img className="flower" src={flower} alt="Flower" />
        </div>

        <div className="side-content">
          <div className="profile">
            <div
              className="profile-img bg-img"
              style={{ backgroundImage: `url(${logo})` }}
            ></div>
          </div>

          <div className="side-menu">
            <ul>
              {filteredOptions.map((ss) => (
                <li key={ss.id}>
                  <Link
                    to={ss.link}
                    className={activeId === ss.id ? "active" : ""}
                    onClick={() => setActiveId(ss.id)}
                  >
                    <div className="div_icon_sid">
                      <img
                        className="icon_sid"
                        src={activeId === ss.id ? ss.icon_white : ss.icon}
                        alt="img-side"
                      />
                      <small>
                        {langmiamed === "ar" ? ss.name_ar : ss.name}
                      </small>
                    </div>
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      {openInternet && (
        <NoInternet open={openInternet} setOpen={setopenInternet} />
      )}
    </>
  );
}

export default Sidebar;

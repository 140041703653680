import * as types from "../ActionType";

const initState = {
    users: [],
    loadingUsers: true,
    lasts: [],
    loadingLasts: true,
    senior_supervisor: [],
    loadingsenior_supervisor: true,
    profile: {},
    loadingProfile: true,
    permissionss: [],
    loadingPer: true,
    notify: [],
    loadingNotify: true
}

const AuthReducer = (state = initState, action) => {
    switch (action.type) {
        case types.LOGIN:
            return { ...state }
        case types.LOGOUT:
            return { ...state }
        case types.SAVE_FCM:
            return { ...state }
        case types.GET_ALL_USERS:
            return { ...state, users: action.payload, loadingUsers: false }
        case types.GET_ALL_LASTS:
            return { ...state, lasts: action.payload, loadingLasts: false }
        case types.ADD_USER:
            return { ...state, loadingUsers: true }
        case types.DELETE_USER:
            return { ...state, loadingUsers: true }
        case types.EDIT_USER:
            return { ...state, loadingUsers: true }
        case types.GET_SENIOR_SUPERVISOR:
            return { ...state, senior_supervisor: action.payload, loadingsenior_supervisor: false }
        case types.EMPTY_SENIOR_SUPERVISOR:
            return { ...state, senior_supervisor: action.payload, loadingsenior_supervisor: true }
        case types.GET_INFORMATION_PROFILE:
            return { ...state, profile: action.payload, loadingProfile: true }
        case types.GET_ALL_PERMISSIONS:
            return { ...state, permissionss: action.payload, loadingPer: true }
        case types.GET_NOTIFICATIONS:
            return { ...state, notify: action.payload, loadingNotify: false }
        default:
            return state;
    }
};

export default AuthReducer;

// import React, { useState } from "react";
// import ReactLoading from "react-loading";
// import Select from "react-select";
// import { useTranslation } from "react-i18next";
// import { AbstructVar } from "./AbstructVar";

// function MedicalRepsReportsSelect({
//   testarr,
//   selectedOptions,
//   setSelectedOptions,
//   typeCss,
//   funb,
//   load,
//   is_width,
//   setSelectedIds,
//   selectedIds,
// }) {
//   let { t } = useTranslation();

//   // const toggleOption = (option) => {
//   //   const selectedOptionIds = selectedOptions.map((opt) => opt.id);
//   //   if (selectedOptionIds.includes(option.id)) {
//   //     setSelectedOptions(selectedOptions.filter((opt) => opt.id !== option.id));
//   //   } else {
//   //     setSelectedOptions([...selectedOptions, option]);
//   //   }
//   // };
//   let { langmiamed } = AbstructVar();
//   const customStyles = {
//     control: (provided) => ({
//       ...provided,
//       borderRadius: "8px",
//       borderColor: "#ccc",
//       boxShadow: "none",
//       fontSize:'13px !important'
//     }),
//     placeholder: (provided) => ({
//       ...provided,
//       color: "gray",
//       textAlign: langmiamed === "ar" ? "right" : "left",
//       fontFamily: "GE SS TWO",
//       fontSize:'13px !important'
//     }),
//     multiValue: (provided) => ({
//       ...provided,
//       backgroundColor: "#f0f0f0",
//       borderRadius: "4px",
//       padding: "2px 4px",
//     }),
//     option: (provided, state) => ({
//       ...provided,
//       fontSize:'13px !important',

//       fontFamily: "GE SS TWO",
//     }),
//   };
//   const options = testarr.map((option) => ({
//     value: option.id,
//     label: option.name,
//   }));

//   return (
//     <div className="select-holder">
//       <Select
//         options={options}
//         isMulti
//         value={selectedOptions}
//         onChange={(selectedValues) => {
//           const selectedIds = selectedValues.map((value) => value.value);
//           setSelectedIds(selectedIds);
//           setSelectedOptions(selectedValues);
//         }}
//         placeholder={t("Select a Medical Representative")}
//         styles={{
//           placeholder: (provided) => ({
//             ...provided,
//             color: "gray",
//             textAlign: langmiamed === "ar" ? "right" : "left",
//             fontFamily: "Open Sans, sans-serif",
//           }),
//           menu: (provided) => ({
//             ...provided,
//             textAlign: langmiamed === "ar" ? "right" : "left",
//           }),
//           control: (provided) => ({
//             ...provided,
//             fontFamily: "Open Sans, sans-serif",
//             fontSize:'13px !important'
//           }),
//           option: (provided, state) => ({
//             ...provided,
//             fontSize:'13px !important',
      
//             fontFamily: "GE SS TWO",
//           }),
//         }}
//       />

//       {/* {open && (
//         <div className="select_creatable">
//           {load === false ? (
//             testarr.map((option) => (
//               <div key={option.id} className="lable_multi">
//                 <input
//                   type="checkbox"
//                   checked={selectedOptions.some((opt) => opt.id === option.id)}
//                   onChange={() => toggleOption(option)}
//                 />
//                 <span>{option.name}</span>
//               </div>
//             ))
//           ) : (
//             <div className="loadinselect">
//               <ReactLoading
//                 type="spinningBubbles"
//                 color="#9699c2"
//                 height={"7%"}
//                 width={"7%"}
//               />
//             </div>
//           )}
//         </div>
//       )} */}
//     </div>
//   );
// }

// export default MedicalRepsReportsSelect;



import React from "react";
import ReactLoading from "react-loading";
import Select, { components } from "react-select";
import { useTranslation } from "react-i18next";
import { AbstructVar } from "./AbstructVar";

// Custom Option component to include a checkbox before the label
const CheckboxOption = ({ children, ...props }) => {
  return (
    <components.Option {...props}>
      <input
        type="checkbox"
        checked={props.isSelected}
        onChange={() => null} // No-op, react-select handles the change
        style={{ marginRight: 8}}
      />
      {children}
    </components.Option>
  );
};

function MedicalRepsReportsSelect({
  testarr,
  selectedOptions,
  setSelectedOptions,
  typeCss,
  funb,
  load,
  is_width,
  setSelectedIds,
  selectedIds,
}) {
  let { t } = useTranslation();
  let { langmiamed } = AbstructVar();

  const customStyles = {
    control: (provided) => ({
      ...provided,
      borderRadius: "8px",
      borderColor: "#ccc",
      boxShadow: "none",
      fontSize: '13px !important',
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "gray",
      textAlign: langmiamed === "ar" ? "right" : "left",
      fontFamily: "GE SS TWO",
      fontSize: '13px !important',
    }),
    multiValue: (provided) => ({
      ...provided,
      backgroundColor: "#f0f0f0",
      borderRadius: "4px",
      padding: "2px 4px",
    }),
    option: (provided, state) => ({
      ...provided,
      fontSize: '13px !important',
      fontFamily: "GE SS TWO",
    }),
  };

  // Convert testarr to format compatible with react-select
  const options = testarr.map((option) => ({
    value: option.id,
    label: option.name,
  }));

  return (
    <div className="select-holder">
      <Select
        options={options}
        isMulti
        value={selectedOptions}
        onChange={(selectedValues) => {
          const selectedIds = selectedValues.map((value) => value.value);
          setSelectedIds(selectedIds);
          setSelectedOptions(selectedValues);
        }}
        placeholder={t("Select a Medical Representative")}
        closeMenuOnSelect={false} // Prevent the dropdown from closing when an option is selected
        hideSelectedOptions={false} // Show selected options with checkboxes
        components={{ Option: CheckboxOption }} // Use custom checkbox option
        isSearchable // Enable search functionality
        styles={customStyles} // Apply custom styles
      />

      {load && (
        <div className="loadinselect">
          <ReactLoading
            type="spinningBubbles"
            color="#9699c2"
            height={"7%"}
            width={"7%"}
          />
        </div>
      )}
    </div>
  );
}

export default MedicalRepsReportsSelect;

import { useState } from "react";
import { CheckFont } from "../General/GeneralComponent/CheckLang";

export const VariableReports = () => {
  const [state, setState] = useState({
    openMap: false,
    lat: false,
    lng: false,
    isTap: "target_group_visits",
  });
  const storeMap = (latt, lngg) => {
    setState((prevState) => ({
      ...prevState,
      lat: latt,
      lng: lngg,
      openMap: true,
    }));
  };
  const setIsTap = (value) => {
    setState((prevState) => ({
      ...prevState,
      isTap: value,
    }));
  };
  const setIsOpen = (value) => {
    setState((prevState) => ({
      ...prevState,
      openMap: value,
    }));
  };
  return {
    ...state,
    state,
    setState,
    storeMap,
    setIsTap,
    setIsOpen,
  };
};

export const ColumnArrayReports = (translate, storeMap) => {
  const Column = [
    {
      name: <span className="color_spann">{translate("target_group")}</span>,
      sortable: true,
      selector: row => row.target_group_name,

      cell: (row) => (
        <span style={{ fontFamily: CheckFont(row.target_group_name) }}>
          {row["target_group_name"]}
        </span>
      ),
    },
    {
      name: <span className="color_spann">{translate("address")}</span>,
      sortable: true,
      selector: row => row.target_group_address,

      cell: (row) => (
        <span style={{ fontFamily: CheckFont(row.target_group_address) }}>
          {row["target_group_address"]}
        </span>
      ),
    },
    {
      name: <span className="color_spann">{translate("delegates")}</span>,
      sortable: true,
      selector: row => row.medical_rep_name,

      cell: (row) => (
        <span style={{ fontFamily: CheckFont(row.medical_rep_name) }}>
          {row["medical_rep_name"]}
        </span>
      ),
      width: "150px",
    },
    {
      name: <span className="color_spann">{translate("target_type")}</span>,
      sortable: true,
      selector: row => row.target_group_type,

      cell: (row) => (
        <span style={{ fontFamily: CheckFont(row.target_group_type) }}>
          {row["target_group_type"]}
        </span>
      ),
    },
    {
      name: <span className="color_spann">{translate("location")}</span>,
      sortable: true,

      cell: (row) => (
        <span>
          {row.site_match ? (
            <i className="las la-check checkmatch"></i>
          ) : (
            <i className="las la-times falsematch"></i>
          )}
        </span>
      ),
    },
    {
      name: <span className="color_spann">{translate("reportType")}</span>,
      sortable: true,
      selector: row => row.report_type_name,

      cell: (row) => (
        <span style={{ fontFamily: CheckFont(row.report_type_name) }}>
          {row["report_type_name"]}
        </span>
      ),
    },
    {
      name: <span className="color_spann">{translate("date")}</span>,
      sortable: true,
      selector: row => row.visit_date,

      cell: (row) => (
        <span style={{ fontFamily: CheckFont(row.visit_date) }}>
          {row["visit_date"]}
        </span>
      ),
    },
    {
      name: <span className="color_spann">{translate("houre")}</span>,
      sortable: true,
      selector: row => row.visit_time,

      cell: (row) => (
        <span style={{ fontFamily: CheckFont(row.visit_time) }}>
          {row["visit_time"]}
        </span>
      ),
    },
    {
      name: (
        <span width="100%" className="color_spann">
          {translate("description")}
        </span>
      ),
      sortable: true,
      selector: row => row.visit_details,

      cell: (row) => (
        <span style={{ fontFamily: CheckFont(row.visit_details) }}>
          {row["visit_details"]}
        </span>
      ),
      width: "250px",
    },
    {
      name: <span className="color_spann">{translate("map")}</span>,
      cell: (row) => (
        <i
          className="las la-map-marked icon_edit_table"
          onClick={() => storeMap(row.lat, row.lng)}
        ></i>
      ),
    },
  ];
  return {
    Column,
  };
};

export const ColumnArrayReportHospital = (translate, storeMap) => {
  const HospitalReqsColumn = [
    {
      name: <span className="color_spann">{translate("date")}</span>,
      sortable: true,
      selector: row => row.visit_date,

      cell: (row) => (
        <span style={{ fontFamily: CheckFont(row.visit_date) }}>
          {row["visit_date"]}
        </span>
      ),
    },
    {
      name: <span className="color_spann">{translate("time")}</span>,
      sortable: true,
      selector: row => row.visit_time,

      cell: (row) => (
        <span style={{ fontFamily: CheckFont(row.visit_time) }}>
          {row["visit_time"]}
        </span>
      ),
    },
    {
      name: <span className="color_spann">{translate("medical_rep_name")}</span>,
      sortable: true,
      selector: row => row.medical_rep_name,

      cell: (row) => (
        <span style={{ fontFamily: CheckFont(row.medical_rep_name) }}>
          {row["medical_rep_name"]}
        </span>
      ),
    },
    {
      name: <span className="color_spann">{translate("targe_group_name")}</span>,
      sortable: true,
      selector: row => row.targe_group_name,

      cell: (row) => (
        <span style={{ fontFamily: CheckFont(row.targe_group_name) }}>
          {row["targe_group_name"]}
        </span>
      ),
    },
    {
      name: <span className="color_spann">{translate("doctor_name")}</span>,
      sortable: true,
      selector: row => row.doctor,

      cell: (row) => (
        <span style={{ fontFamily: CheckFont(row.doctor) }}>
          {row["doctor"]}
        </span>
      ),
    },
    {
      name: <span className="color_spann">{translate("specialization_name")}</span>,
      sortable: true,
      selector: row => row.specialization_name,

      cell: (row) => (
        <span style={{ fontFamily: CheckFont(row.specialization_name) }}>
          {row["specialization_name"]}
        </span>
      ),
    },
    {
      name: <span className="color_spann">{translate("note")}</span>,
      sortable: true,
      selector: row => row.note,

      cell: (row) => (
        <span style={{ fontFamily: CheckFont(row.note) }}>
          {row["note"]}
        </span>
      ),
    },
    {
      name: <span className="color_spann">{translate("merged")}</span>,
      sortable: true,
      selector: row => row.merged,

      cell: (row) => (
        <span style={{ fontFamily: CheckFont(row.merged) }}>
        {row["merged"] === "1" ? "نعم" : "لا"}
        </span>
      ),
    },
  ];
  return {
    HospitalReqsColumn,
  };
};
export const ColumnArrayReportObjections = (translate, storeMap) => {
  const OrdersReqsColumn = [
    {
      name: <span className="color_spann">{translate("dateTime")}</span>,
      selector: row => row.visit_date,
      cell: (row) => (
        <span style={{ fontFamily: CheckFont(row.visit_date) }}>
          {row["visit_date"]}
        </span>
      ),
      sortable: true,

    },
    {
      id: 'countryName',
      name: <span className="color_spann">{translate("countryName")}</span>,
      selector: row => row.country_name,
      cell: row => (
        <span style={{ fontFamily: CheckFont(row.country_name) }}>
          {row.country_name}
        </span>
      ),
      sortable: true,
    },
    {
      name: <span className="color_spann">{translate("cityName")}</span>,
      sortable: true,
      selector: row => row.city_name,

      cell: (row) => (
        <span style={{ fontFamily: CheckFont(row.city_name) }}>
          {row["city_name"]}
        </span>
      ),
      width: "150px",
    },
    {
      name: (
        <span className="color_spann">{translate("medical_rep_name")}</span>
      ),
      sortable: true,
      selector: row => row.medical_rep_name,

      cell: (row) => (
        <span style={{ fontFamily: CheckFont(row.medical_rep_name) }}>
          {row["medical_rep_name"]}
        </span>
      ),
    },
    {
      name: (
        <span className="color_spann">{translate("target_group_name")}</span>
      ),
      sortable: true,
      selector: row => row.target_group_name,

      cell: (row) => (
        <span style={{ fontFamily: CheckFont(row.target_group_name) }}>
          {row["target_group_name"]}
        </span>
      ),
    },
    {
      name: <span className="color_spann">{translate("Objection")}</span>,
      sortable: true,
      selector: row => row.visit_details,

      cell: (row) => (
        <span style={{ fontFamily: CheckFont(row.visit_details) }}>
          {row["visit_details"]}
        </span>
      ),
    },
  ];
  return {
    OrdersReqsColumn,
  };
};
export const ColumnArrayReportRequests = (translate, storeMap) => {
  const OrdersReqsColumn = [
    {
      name: <span className="color_spann">{translate("date")}</span>,
      sortable: true,
      selector: row => row.visit_date,
      cell: (row) => (
        <span style={{ fontFamily: CheckFont(row.visit_date) }}>
          {row["visit_date"]}
        </span>
      ),
    },
    {
      name: <span className="color_spann">{translate("countryName")}</span>,
      sortable: true,
      selector: row => row.country_name,

      cell: (row) => (
        <span style={{ fontFamily: CheckFont(row.country_name) }}>
          {row["country_name"]}
        </span>
      ),
    },
    {
      name: <span className="color_spann">{translate("cityName")}</span>,
      sortable: true,
      selector: row => row.city_name,

      cell: (row) => (
        <span style={{ fontFamily: CheckFont(row.city_name) }}>
          {row["city_name"]}
        </span>
      ),
      width: "150px",
    },
    {
      name: (
        <span className="color_spann">{translate("medical_rep_name")}</span>
      ),
      sortable: true,
      selector: row => row.medical_rep_name,

      cell: (row) => (
        <span style={{ fontFamily: CheckFont(row.medical_rep_name) }}>
          {row["medical_rep_name"]}
        </span>
      ),
    },
    {
      name: (
        <span className="color_spann">{translate("target_group_name")}</span>
      ),
      sortable: true,
      selector: row => row.target_group_name,

      cell: (row) => (
        <span style={{ fontFamily: CheckFont(row.target_group_name) }}>
          {row["target_group_name"]}
        </span>
      ),
    },
    {
      name: <span className="color_spann">{translate("Request")}</span>,
      sortable: true,
      selector: row => row.visit_details,

      cell: (row) => (
        <span style={{ fontFamily: CheckFont(row.visit_details) }}>
          {row["visit_details"]}
        </span>
      ),
    },
  ];
  return {
    OrdersReqsColumn,
  };
};
export const ColumnArrayMonthlyOrders = (translate, storeMap) => {
  const OrdersReqsColumn = [
    {
      name: <span className="color_spann">{translate("dateTime")}</span>,
      sortable: true,
      selector: row => row.visit_date,

      cell: (row) => (
        <span style={{ fontFamily: CheckFont(row.visit_date) }}>
          {row["visit_date"]}
        </span>
      ),
    },
    {
      name: <span className="color_spann">{translate("countryName")}</span>,
      sortable: true,
      selector: row => row.country_name,

      cell: (row) => (
        <span style={{ fontFamily: CheckFont(row.country_name) }}>
          {row["country_name"]}
        </span>
      ),
    },
    {
      name: <span className="color_spann">{translate("cityName")}</span>,
      sortable: true,
      selector: row => row.city_name,

      cell: (row) => (
        <span style={{ fontFamily: CheckFont(row.city_name) }}>
          {row["city_name"]}
        </span>
      ),
      width: "150px",
    },
    {
      name: (
        <span className="color_spann">{translate("medical_rep_name")}</span>
      ),
      sortable: true,
      selector: row => row.medical_rep_name,

      cell: (row) => (
        <span style={{ fontFamily: CheckFont(row.medical_rep_name) }}>
          {row["medical_rep_name"]}
        </span>
      ),
    },
    {
      name: (
        <span className="color_spann">{translate("target_group_name")}</span>
      ),
      sortable: true,
      selector: row => row.target_group_name,

      cell: (row) => (
        <span style={{ fontFamily: CheckFont(row.target_group_name) }}>
          {row["target_group_name"]}
        </span>
      ),
    },
    {
      name: <span className="color_spann">{translate("product_Name")}</span>,
      sortable: true,
      selector: row => row.product_name,

      cell: (row) => (
        <span style={{ fontFamily: CheckFont(row.product_name) }}>
          {row["product_name"]}
        </span>
      ),
    },
    {
      name: <span className="color_spann">{translate("quantity")}</span>,
      sortable: true,
      selector: row => row.quantityProds,

      cell: (row) => (
        <span style={{ fontFamily: CheckFont(row.quantityProds) }}>
          {row["quantityProds"]}
        </span>
      ),
    },
  ];
  return {
    OrdersReqsColumn,
  };
};
export const ColumnArrayMonthlyProductsSales = (translate, storeMap) => {
  const productsMonthleSalesColumn = [
    {
      name: (
        <span className="color_spann">{translate("medical_rep_name")}</span>
      ),
      sortable: true,
      selector: row => row.medical_rep_name,

      cell: (row) => (
        <span style={{ fontFamily: CheckFont(row.medical_rep_name) }}>
          {row["medical_rep_name"]}
        </span>
      ),
    },
    {
      name: <span className="color_spann">{translate("pro_name")}</span>,
      sortable: true,
      selector: row => row.name,

      cell: (row) => (
        <span style={{ fontFamily: CheckFont(row.name) }}>{row["name"]}</span>
      ),
    },
    {
      name: <span className="color_spann">{translate("target_product")}</span>,
      sortable: true,
      selector: row => row.target,

      cell: (row) => (
        <span style={{ fontFamily: CheckFont(row.target) }}>
          {row["target"]}
        </span>
      ),
    },
    {
      name: <span className="color_spann">{translate("achived_product")}</span>,
      sortable: true,
      selector: row => row.achive,

      cell: (row) => (
        <span style={{ fontFamily: CheckFont(row.achive) }}>
          {row["achive"]}
        </span>
      ),
    },
  ];
  return {
    productsMonthleSalesColumn,
  };
};
export const ColumnArrayMedicalRepTarget = (translate, storeMap) => {
  const medicalRepTargetColumn = [
    {
      name: (
        <span className="color_spann">{translate("medical_rep_name")}</span>
      ),
      sortable: true,
      selector: row => row.medical_rep_name,

      cell: (row) => (
        <span style={{ fontFamily: CheckFont(row.medical_rep_name) }}>
          {row["medical_rep_name"]}
        </span>
      ),
    },
    {
      name: <span className="color_spann">{translate("total_target")}</span>,
      sortable: true,
      selector: row => row.target_medical_reps_quantity,

      cell: (row) => (
        <span
          style={{ fontFamily: CheckFont(row.target_medical_reps_quantity) }}
        >
          {row["target_medical_reps_quantity"]}
        </span>
      ),
    },

    {
      name: (
        <span className="color_spann">{translate("total_achieved_sales")}</span>
      ),
      sortable: true,
      selector: row => row.target_medical_reps_quantity2,

      cell: (row) => (
        <span
          style={{ fontFamily: CheckFont(row.target_medical_reps_quantity2) }}
        >
          {row["target_medical_reps_quantity2"]}
        </span>
      ),
    },
    {
      name: <span className="color_spann">{translate("percentage")}</span>,
      sortable: true,
      selector: row => row.target_medical_reps_quantity3,

      cell: (row) => (
        <span
          style={{ fontFamily: CheckFont(row.target_medical_reps_quantity3) }}
        >
          {row["target_medical_reps_quantity3"]}
        </span>
      ),
    },
  ];
  return {
    medicalRepTargetColumn,
  };
};
export const ColumnArrayReportTargetVisits = (translate, storeMap) => {
  const OrdersReqsColumn = [
    // {
    //   name: <span className="color_spann">{translate("dateTime")}</span>,
    //   sortable: true,
    //   cell: (row) => (
    //     <span style={{ fontFamily: CheckFont(row.date) }}>{row["date"]}</span>
    //   ),
    // },

    {
      name: (
        <span className="color_spann">{translate("medical_rep")}</span>
      ),
      sortable: true,
      selector: row => row.medical_rep_name,

      cell: (row) => (
        <span style={{ fontFamily: CheckFont(row.medical_rep_name) }}>
          {row["medical_rep_name"]}
        </span>
      ),
      width: "200px",
    },
  //  
  {
    name: (
      <span className="color_spann">{translate("merged")}</span>
    ),
    sortable: true,
    selector: row => row.merged,

    cell: (row) => (
      <span style={{ fontFamily: CheckFont(row.merged) }}>
        {row["merged"] === 1 ? "نعم" : "لا"}
      </span>
    ),
    width: "200px",
  },
  // 
    {
      name: (
        <span className="color_spann">{translate("target_type_name")}</span>
      ),
      sortable: true,
      selector: row => row.target_type_name,
      selector: row => row.targe_group_name,

      cell: (row) => (
        <div className="d-flex flex-column">
          <span style={{ fontFamily: CheckFont(row.target_type_name) }}>
            {row["target_type_name"]}
          </span>
         
          <span style={{ fontFamily: CheckFont(row.targe_group_name) }}>
            {row["targe_group_name"]}
          </span>{" "}
      </div>
      ),
    },
    {
      name: (
        <span className="color_spann">{translate("specialization_name")}</span>
      ),
      sortable: true,
      selector: row => row.specialization_name,

      cell: (row) => (
        <span style={{ fontFamily: CheckFont(row.specialization_name) }}>
          {row["specialization_name"]}
        </span>
      ),
    },

    {
      name: (
        <span className="color_spann">{translate("classification_name")}</span>
      ),
      sortable: true,
      selector: row => row.classification_name,

      cell: (row) => (
        <span style={{ fontFamily: CheckFont(row.classification_name) }}>
          {row["classification_name"]}
        </span>
      ),
    },

    {
      name: <span className="color_spann">{translate("number_of_visit")}</span>,
      sortable: true,
      selector: row => row.count,

      cell: (row) => (
        <span style={{ fontFamily: CheckFont(row.count) }}>{row["count"]}</span>
      ),
    },
  ];
  return {
    OrdersReqsColumn,
  };
};
export const ColumnArrayReportTargetVisitsWithDate = (translate, storeMap) => {
  const OrdersReqsColumn = [
    {
      name: <span className="color_spann">{translate("date")}</span>,
      sortable: true,
      selector: row => row.visit_date,

      cell: (row) => (
        <span style={{ fontFamily: CheckFont(row.visit_date) }}>{row["visit_date"]}</span>
      ),
    },
    {
      selector: row => row.visit_time,

      name: <span className="color_spann">{translate("time_visit")}</span>,
      sortable: true,
      cell: (row) => (
        <span style={{ fontFamily: CheckFont(row.visit_time) }}>{row["visit_time"]}</span>
      ),
    },

    {
      name: (
        <span className="color_spann">{translate("medical_rep")}</span>
      ),
      sortable: true,
      selector: row => row.medical_rep_name,

      cell: (row) => (
        <span style={{ fontFamily: CheckFont(row.medical_rep_name) }}>
          {row["medical_rep_name"]}
        </span>
      ),
      width: "200px",
    },
    {
      name: (
        <span className="color_spann">{translate("merged")}</span>
      ),
      sortable: true,
      selector: row => row.merged,
  
      cell: (row) => (
        <span style={{ fontFamily: CheckFont(row.merged) }}>
          {row["merged"] === "1" ? "نعم" : "لا"}
        </span>
      ),
      width: "200px",
    },
    {
      name: (
        <span className="color_spann">{translate("target_type_name")}</span>
      ),
      sortable: true,
      selector: row => row.target_type_name,
      selector: row => row.targe_group_name,

      cell: (row) => (
        <div className="d-flex flex-column">
          <span style={{ fontFamily: CheckFont(row.target_type_name) }}>
            {row["target_type_name"]}
          </span>
         
          <span style={{ fontFamily: CheckFont(row.targe_group_name) }}>
            {row["targe_group_name"]}
          </span>{" "}
      </div>
      ),
    },
    {
      name: (
        <span className="color_spann">{translate("specialization_name")}</span>
      ),
      sortable: true,
      selector: row => row.specialization_name,

      cell: (row) => (
        <span style={{ fontFamily: CheckFont(row.specialization_name) }}>
          {row["specialization_name"]}
        </span>
      ),
    },

    {
      name: (
        <span className="color_spann">{translate("classification_name")}</span>
      ),
      sortable: true,
      selector: row => row.classification_name,

      cell: (row) => (
        <span style={{ fontFamily: CheckFont(row.classification_name) }}>
          {row["classification_name"]}
        </span>
      ),
    },
    {
      name: (
        <span className="color_spann">{translate("report_type")}</span>
      ),
      sortable: true,
      selector: row => row.note,
      cell: (row) => (
        <span style={{ fontFamily: CheckFont(row.note) }}>
          {row["note"]}
        </span>
      ),
    },

  ];
  return {
    OrdersReqsColumn,
  };
};

export const ColumnArrayReportAllVisits = (translate, storeMap) => {
  const OrdersReqsColumn = [
    // {
    //   name: <span className="color_spann">{translate("dateTime")}</span>,
    //   sortable: true,
    //   cell: (row) => (
    //     <span style={{ fontFamily: CheckFont(row.date) }}>{row["date"]}</span>
    //   ),
    //   width: "150px",
    // },
    
    {
      name: <span className="color_spann">{translate("GorvernanteName")}</span>,
      sortable: true,
      selector: row => row.country_name,
      cell: (row) => (
        <span style={{ fontFamily: CheckFont(row.country_name) }}>
          {row["country_name"]}
        </span>
      ),
      width: "150px",
    },
    {
      name: <span className="color_spann">{translate("cityName")}</span>,
      sortable: true,
      selector: row => row.city_name,
      cell: (row) => (
        <span style={{ fontFamily: CheckFont(row.city_name) }}>
          {row["city_name"]}
        </span>
      ),
    },
    {
      name: (
        <span className="color_spann">{translate("medical_rep")}</span>
      ),
      sortable: true,
      selector: row => row.medical_rep_name,

      cell: (row) => (
        <span style={{ fontFamily: CheckFont(row.medical_rep_name) }}>
          {row["medical_rep_name"]}
        </span>
      ),
    },

    {
      name: <span className="color_spann">{translate("planed_visits")}</span>,
      sortable: true,
      selector: row => row.planed_visits,

      cell: (row) => (
        <span style={{ fontFamily: CheckFont(row.planed_visits) }}>
          {row["planed_visits"]}
        </span>
      ),
    },
    {
      name: <span className="color_spann">{translate("actual_visits")}</span>,
      sortable: true,
      selector: row => row.actual_visits,

      cell: (row) => (
        <span style={{ fontFamily: CheckFont(row.actual_visits) }}>
          {row["actual_visits"]}
        </span>
      ),
    },
    {
      name: (
        <span className="color_spann">
          {translate("achieved_rate_of_visits")}
        </span>
      ),
      sortable: true,
      selector: row => row.achieved_rate_of_visits,

      cell: (row) => (
        <span style={{ fontFamily: CheckFont(row.achieved_rate_of_visits) }}>
          {row["achieved_rate_of_visits"]}
        </span>
      ),
    },
    {
      name: (
        <span className="color_spann">{translate("unachieved_visits")}</span>
      ),
      sortable: true,
      selector: row => row.unachieved_visits,

      cell: (row) => (
        <span style={{ fontFamily: CheckFont(row.unachieved_visits) }}>
          {row["unachieved_visits"]}
        </span>
      ),
    },

    {
      name: (
        <span className="color_spann">{translate("over_visited_target")}</span>
      ),
      sortable: true,
      selector: row => row.over_visited_target,

      cell: (row) => (
        <span style={{ fontFamily: CheckFont(row.over_visited_target) }}>
          {row["over_visited_target"]}
        </span>
      ),
    },
    {
      name: <span className="color_spann">{translate("new_target")}</span>,
      sortable: true,
      selector: row => row.new_target,

      cell: (row) => (
        <span style={{ fontFamily: CheckFont(row.new_target) }}>
          {row["new_target"]}
        </span>
      ),
    },
  ];
  return {
    OrdersReqsColumn,
  };
};
export const ColumnArrayReportFreeSamples = (translate, storeMap) => {
  const OrdersReqsColumn = [
    {
      name: <span className="color_spann">{translate("countryName")}</span>,
      sortable: true,
      selector: row => row.countryName,

      cell: (row) => (
        <span style={{ fontFamily: CheckFont(row.countryName) }}>
          {row["countryName"]}
        </span>
      ),
    },
    {
      name: <span className="color_spann">{translate("medicalName")}</span>,
      sortable: true,
      selector: row => row.medicalName,

      cell: (row) => (
        <span style={{ fontFamily: CheckFont(row.medicalName) }}>
          {row["medicalName"]}
        </span>
      ),
    },

    {
      name: <span className="color_spann">{translate("freeSamplesName")}</span>,
      sortable: true,
      selector: row => row.productName,

      cell: (row) => (
        <span style={{ fontFamily: CheckFont(row.productName) }}>
          {row["productName"]}
        </span>
      ),
      width: "200px",
    },

    {
      name: (
        <span className="color_spann">
          {translate("freeSamplesQuantity")}
        </span>
      ),
      sortable: true,
      selector: row => row.freeSamplesQuantity,

      cell: (row) => (
        <span style={{ fontFamily: CheckFont(row.freeSamplesQuantity) }}>
          {row["freeSamplesQuantity"]}
        </span>
      ),
    },
    {
      name: <span className="color_spann">{translate("batchNumber")}</span>,
      sortable: true,
      selector: row => row.batchNumber,

      cell: (row) => (
        <span style={{ fontFamily: CheckFont(row.batchNumber) }}>
          {row["batchNumber"]}
        </span>
      ),
    },
  ];
  return {
    OrdersReqsColumn,
  };
};
export const ColumnArrayReportFreeSamplesTargets = (translate, storeMap) => {
  const OrdersReqsColumn = [
    {
      name: <span className="color_spann">{translate("date")}</span>,
      sortable: true,
      selector: row => row.date,

      cell: (row) => (
        <span style={{ fontFamily: CheckFont(row.date) }}>{row["date"]}</span>
      ),
    },
    {
      name: <span className="color_spann">{translate("countryName")}</span>,
      sortable: true,
      selector: row => row.countryName,

      cell: (row) => (
        <span style={{ fontFamily: CheckFont(row.countryName) }}>
          {row["countryName"]}
        </span>
      ),
    },
    {
      name: <span className="color_spann">{translate("cityName")}</span>,
      sortable: true,
      selector: row => row.cityName,

      cell: (row) => (
        <span style={{ fontFamily: CheckFont(row.cityName) }}>
          {row["cityName"]}
        </span>
      ),
    },
    {
      name: <span className="color_spann">{translate("medicalName")}</span>,
      sortable: true,
      selector: row => row.medicalName,

      cell: (row) => (
        <span style={{ fontFamily: CheckFont(row.medicalName) }}>
          {row["medicalName"]}
        </span>
      ),
    },

    {
      name: <span className="color_spann">{translate("targetName")}</span>,
      sortable: true,
      selector: row => row.targetName,

      cell: (row) => (
        <span style={{ fontFamily: CheckFont(row.targetName) }}>
          {row["targetName"]}
        </span>
      ),
      width: "150px",
    },
    {
      name: <span className="color_spann">{translate("freeSamplesName")}</span>,
      sortable: true,
      selector: row => row.freeSamplesName,

      cell: (row) => (
        <span style={{ fontFamily: CheckFont(row.freeSamplesName) }}>
          {row["freeSamplesName"]}
        </span>
      ),
      width: "150px",
    },
    {
      name: <span className="color_spann">{translate("Code")}</span>,
      sortable: true,
      selector: row => row.code,

      cell: (row) => (
        <span style={{ fontFamily: CheckFont(row.code) }}>{row["Code"]}</span>
      ),
      width: "150px",
    },
    {
      name: <span className="color_spann">{translate("batchNumber")}</span>,
      sortable: true,
      selector: row => row.batchNumber,

      cell: (row) => (
        <span style={{ fontFamily: CheckFont(row.batchNumber) }}>
          {row["batchNumber"]}
        </span>
      ),
      width: "150px",
    },
    {
      name: (
        <span className="color_spann">{translate("freeSamplesQuantity")}</span>
      ),
      sortable: true,
      selector: row => row.freeSamplesQuantity,

      cell: (row) => (
        <span style={{ fontFamily: CheckFont(row.freeSamplesQuantity) }}>
          {row["freeSamplesQuantity"]}
        </span>
      ),
      width: "150px",
    },
    // {
    //   name: <span className="color_spann">{translate("productName")}</span>,
    //   sortable: true,
    //   cell: (row) => (
    //     <span style={{ fontFamily: CheckFont(row.productName) }}>
    //       {row["productName"]}
    //     </span>
    //   ),
    //   width: "150px",
    // },
  ];
  return {
    OrdersReqsColumn,
  };
};
export const ColumnArrayReportMarketingTools = (translate, storeMap) => {
  const OrdersReqsColumn = [
    {
      name: <span className="color_spann">{translate("date")}</span>,
      sortable: true,
      selector: row => row.date,

      cell: (row) => (
        <span style={{ fontFamily: CheckFont(row.date) }}>{row["date"]}</span>
      ),
    },
    {
      name: <span className="color_spann">{translate("countryName")}</span>,
      sortable: true,
      selector: row => row.countryName,

      cell: (row) => (
        <span style={{ fontFamily: CheckFont(row.countryName) }}>
          {row["countryName"]}
        </span>
      ),
    },
    {
      name: <span className="color_spann">{translate("cityName")}</span>,
      sortable: true,
      selector: row => row.cityName,

      cell: (row) => (
        <span style={{ fontFamily: CheckFont(row.cityName) }}>
          {row["cityName"]}
        </span>
      ),
    },
    {
      name: <span className="color_spann">{translate("medicalName")}</span>,
      sortable: true,
      selector: row => row.medicalName,

      cell: (row) => (
        <span style={{ fontFamily: CheckFont(row.medicalName) }}>
          {row["medicalName"]}
        </span>
      ),
    },

    {
      name: <span className="color_spann">{translate("targetName")}</span>,
      sortable: true,
      selector: row => row.targetName,

      cell: (row) => (
        <span style={{ fontFamily: CheckFont(row.targetName) }}>
          {row["targetName"]}
        </span>
      ),
      width: "150px",
    },

    {
      name: (
        <span className="color_spann">{translate("marketingToolName")}</span>
      ),
      sortable: true,
      selector: row => row.toolName,

      cell: (row) => (
        <span style={{ fontFamily: CheckFont(row.toolName) }}>
          {row["toolName"]}
        </span>
      ),
    },
    {
      name: <span className="color_spann">{translate("Code")}</span>,
      sortable: true,
      selector: row => row.code,

      cell: (row) => (
        <span style={{ fontFamily: CheckFont(row.code) }}>{row["code"]}</span>
      ),
    },
    {
      name: (
        <span className="color_spann">
          {translate("marketingToolsQuantity")}
        </span>
      ),
      sortable: true,
      selector: row => row.marketingToolsQuantity,

      cell: (row) => (
        <span style={{ fontFamily: CheckFont(row.marketingToolsQuantity) }}>
          {row["marketingToolsQuantity"]}
        </span>
      ),
    },
  ];
  return {
    OrdersReqsColumn,
  };
};
export const ColumnArrayReportGetProductsStock = (translate, storeMap) => {
  const ProductsStockReqsColumn = [
    {
      name: <span className="color_spann">{translate("medical_rep_name")}</span>,
      sortable: true,
      selector: row => row.medical_rep_name,

      cell: (row) => (
        <span style={{ fontFamily: CheckFont(row.medical_rep_name) }}>{row["medical_rep_name"]}</span>
      ),
    },
    {
      name: <span className="color_spann">{translate("product_name")}</span>,
      sortable: true,
      selector: row => row.product_name,

      cell: (row) => (
        <span style={{ fontFamily: CheckFont(row.product_name) }}>
          {row["product_name"]}
        </span>
      ),
    },
    {
      name: <span className="color_spann">{translate("total_quantity")}</span>,
      sortable: true,
      selector: row => row.total_quantity,

      cell: (row) => (
        <span style={{ fontFamily: CheckFont(row.total_quantity) }}>
          {row["total_quantity"]}
        </span>
      ),
    },
    {
      name: <span className="color_spann">{translate("total_quantity_left")}</span>,
      sortable: true,
      selector: row => row.total_quantity_left,

      cell: (row) => (
        <span style={{ fontFamily: CheckFont(row.total_quantity_left) }}>
          {row["total_quantity_left"]}
        </span>
      ),
    },

    {
      name: <span className="color_spann">{translate("total_quantity_delivered")}</span>,
      sortable: true,
      selector: row => row.total_quantity_delivered,

      cell: (row) => (
        <span style={{ fontFamily: CheckFont(row.total_quantity_delivered) }}>
          {row["total_quantity_delivered"]}
        </span>
      ),
      width: "150px",
    },
  ];
  return {
    ProductsStockReqsColumn,
  };
};
export const ColumnArrayReportGetMarkitingsStock = (translate, storeMap) => {
  const MarkitingStockReqsColumn = [
    {
      name: <span className="color_spann">{translate("medical_rep_name")}</span>,
      sortable: true,
      selector: row => row.medical_rep_name,

      cell: (row) => (
        <span style={{ fontFamily: CheckFont(row.medical_rep_name) }}>{row["medical_rep_name"]}</span>
      ),
    },
    {
      name: <span className="color_spann">{translate("marketing_tool_name")}</span>,
      sortable: true,
      selector: row => row.marketing_tool_name,

      cell: (row) => (
        <span style={{ fontFamily: CheckFont(row.marketing_tool_name) }}>
          {row["marketing_tool_name"]}
        </span>
      ),
    },
    {
      name: <span className="color_spann">{translate("total_quantity")}</span>,
      sortable: true,
      selector: row => row.total_quantity,

      cell: (row) => (
        <span style={{ fontFamily: CheckFont(row.total_quantity) }}>
          {row["total_quantity"]}
        </span>
      ),
    },
    {
      name: <span className="color_spann">{translate("total_quantity_left")}</span>,
      sortable: true,
      selector: row => row.total_quantity_left,

      cell: (row) => (
        <span style={{ fontFamily: CheckFont(row.total_quantity_left) }}>
          {row["total_quantity_left"]}
        </span>
      ),
    },

    {
      name: <span className="color_spann">{translate("total_quantity_delivered")}</span>,
      sortable: true,
      selector: row => row.total_quantity_delivered,

      cell: (row) => (
        <span style={{ fontFamily: CheckFont(row.total_quantity_delivered) }}>
          {row["total_quantity_delivered"]}
        </span>
      ),
      width: "150px",
    },
  ];
  return {
    MarkitingStockReqsColumn,
  };
};

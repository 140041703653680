import React, { useState } from "react";
import { Modal as RModal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import { useDispatch } from "react-redux";
import { postTask } from "../../../Redux/Actions/ActionTask";
import { useSelectorCustom } from "../../General/GeneralComponent/useSelectorCustom";
import { FunctionsLoading } from "../../Main/Statistics/FunctionsLoading";
import { VariableTask } from "./VariableTask";
import SelectWithSearch from "../../General/GeneralComponent/SelectWithSearch";

function AddTask({ medical_rep_id, date, id, t, langmiamed, open, setOpen }) {
  let dispatch = useDispatch();
  const { TGbymedicalRep, loadingTG } = useSelectorCustom();
  const { LoadTargetByMedicalRepCustom } = FunctionsLoading();
  const { setState, state, handleChangeTask, setLoad } = VariableTask();
  console.log(state);
  const submit = (e) => {
    e.preventDefault();
    dispatch(
      postTask(
        id,
        state.note,
        // state.priority_level,
        state.time,
        date,
        state.status,
        state.target_group_id,
        setLoad,
        setOpen,
        setState,
        state
      )
    );
  };
  return (
    <RModal
      open={open}
      onClose={() => {
        setOpen(false);
      }}
      center
      classNames={{
        modal: "addPop",
      }}
      focusTrapped={false}
    >
      <div className="div_addPop" dir={langmiamed == "ar" ? "rtl" : "ltr"}>
        <h3 className={langmiamed == "ar" ? "textRight" : ""}>
          {t("addTask")}
        </h3>
        <div className={langmiamed == "ar" ? "textRight" : ""}>
          <span>{t("time")}</span>
        </div>
        <input
          type="time"
          value={state.time}
          onChange={(e) => handleChangeTask(e.target.value, "time")}
        />

        {/* <div className={langmiamed == "ar" ? "textRight" : ""}>
          <span>{t("priority")}: </span>
        </div> */}
        {/* <select
          value={
            state.priority_level === "" ? t("priority") : state.priority_level
          }
          onChange={(e) => handleChangeTask(e.target.value, "priority_level")}
        >
          <option disabled="disabled" selected="selected">
            {t("priority")}
          </option>
          <option value="high">{t("high")}</option>
          <option value="medium">{t("Medium")}</option>
          <option value="low">{t("low")}</option>
        </select> */}

        <div className={langmiamed == "ar" ? "textRight" : ""}>
          {t("target_group")}:{" "}
        </div>
        <SelectWithSearch
          load={loadingTG}
          array={TGbymedicalRep}
          setId={(value) => handleChangeTask(value, "target_group_id")}
          func={LoadTargetByMedicalRepCustom}
          loadID={medical_rep_id}
          typeCss={true}
          state={state}
        />

        <div className={langmiamed == "ar" ? "textRight" : ""}>
          {t("note")}:{" "}
        </div>
        <textarea
          value={state.note}
          onChange={(e) => handleChangeTask(e.target.value, "note")}
        />

        {/* <div className={langmiamed == "ar" ? "textRight" : ""}>
          {t("status")}:{" "}
        </div> */}
        {/* <select
          value={state.status === "" ? t("status") : state.status}
          name="status"
          onChange={(e) => handleChangeTask(e.target.value, "status")}
        >
          <option disabled="disabled" selected="selected">
            {t("status")}
          </option>
          <option value="is_done">{t("done")}</option>
          <option value="not_done">{t("notdone")}</option>
        </select> */}

        <button disabled={state.Load} onClick={submit}>
          {state.Load ? t("Loading ...") : `${t("save")}`}
        </button>
      </div>
    </RModal>
  );
}

export default AddTask;

import React, { useEffect, useState } from "react";
import Page_Header from "../General/Page_Header";
import "./Assign.css";
import { loadTGbyMedicalRep } from "../../Redux/Actions/ActionMedicalRep";
import {
  LoadTgMed,
  emptyTgMed,
  emptytargetGroup,
  loadTargetGroup,
} from "../../Redux/Actions/ActionTargetGroup";
import { AbstructVar } from "../General/GeneralComponent/AbstructVar";
import { AbstructLang } from "../General/GeneralComponent/AbstructLang";
import { useSelectorCustom } from "../General/GeneralComponent/useSelectorCustom";
import { FunctionsLoading } from "../Main/Statistics/FunctionsLoading";
import SelectWithSearch from "../General/GeneralComponent/SelectWithSearch";
import { VariableAssign } from "./VariableAssign";
import AssignComponent from "./AssignComponent";
import UnAssignComponent from "./UnAssignComponent";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import InnerLoader from "../General/InnerLoader";

function Assign() {
  let { dispatch, langmiamed, navigate } = AbstructVar();
  const { translate } = AbstructLang();
  const { state, handleChangeAssign } = VariableAssign();
  const {
    medicalRep,
    loadingmedicalRep,
    TGbymedicalRep,
    loadingTG,
    target_group,
    loadingTargetGroup,
  } = useSelectorCustom();
  const { LoadMedicalRepCustom } = FunctionsLoading();
  const [lodaing, setLoading] = useState(false);
  const handleConfirmClick = () => {
    if (state.medical_rep_id !== "") {
      dispatch(emptyTgMed());
      dispatch(loadTGbyMedicalRep(state.medical_rep_id));
      dispatch(emptytargetGroup());
      dispatch(LoadTgMed(state.medical_rep_id));
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(loadTargetGroup(navigate));
    return () => {
      dispatch(emptytargetGroup());
    };
  }, []);

  return (
    <>
      <div
        className={langmiamed == "ar" ? "main-content_ar" : "main-content_en"}
      >
        <main>
          <Page_Header
            langmiamed={langmiamed}
            header={translate("assign")}
            path={`${translate("home")} / ${translate("assign")} `}
          />
        </main>
        <div className="page-content">
          <div className="assign" dir={langmiamed == "ar" ? "rtl" : "ltr"}>
            <div className="all_div_select">
              <h5 className={langmiamed == "ar" ? "textRight" : ""}>
                {translate("selectMed")}
              </h5>
              <div>
                <p
                  className={`seespec ${
                    langmiamed == "ar" ? "textRight" : ""
                  } `}
                >
                  {translate("assignP")}
                </p>
                <SelectWithSearch
                  load={loadingmedicalRep}
                  typeCss={true}
                  array={medicalRep}
                  setId={(value) => handleChangeAssign("medical_rep_id", value)}
                  funb={LoadMedicalRepCustom}
                />
              </div>
            </div>

            <div
              className="confirm-button-container"
              style={{
                display: "flex",
                justifyContent: "center",
                marginBottom: "14px",
              }}
            >
              {/* {state.Load ? (
                <button
                  className="btn btn-primary"
                >
                  <InnerLoader />
                </button>
              ) : (
             
              )} */}

              <button onClick={handleConfirmClick} className="btn btn-primary">
                {translate("confirm")}
              </button>
            </div>

            <div dir="rtl">
              <Tabs direction={"rtl"}>
                <TabList>
                  <Tab>بيانات الفئات المستهدفة</Tab>
                  <Tab>الفئات المستهدفة التابعة لمندوب ما</Tab>
                </TabList>
                <TabPanel>
                  <AssignComponent
                    medical_rep_id={state.medical_rep_id}
                    langmiamed={langmiamed}
                    translate={translate}
                    target_group={target_group}
                    loadingTargetGroup={loadingTargetGroup}
                    dispatch={dispatch}
                  />
                </TabPanel>
                <TabPanel>
                  <UnAssignComponent
                    medical_rep_id={state.medical_rep_id}
                    langmiamed={langmiamed}
                    translate={translate}
                    TGbymedicalRep={TGbymedicalRep}
                    loadingTG={loadingTG}
                    dispatch={dispatch}
                  />
                </TabPanel>
              </Tabs>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Assign;

// import React, { useState } from "react";
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";
// import MultiSelectCheckbox from "../../General/GeneralComponent/MultiSelectCheckbox";
// import { FunctionsLoading } from "./FunctionsLoading";

// import { AbstructVar } from "../../General/GeneralComponent/AbstructVar";
// import {
//   FundownloadAllVisits,
//   FundownloadFreeSamples,
//   FundownloadFreeSamplesTarget,
//   FundownloadMarketingTools,
//   FundownloadReportObjections,
//   FundownloadReportOrders,
//   FundownloadReportOrdersReqs,
//   FundownloadReportTargetGroup,
//   FundownloadTargetGroupVisits,
//   allVisitsReport,
//   freeSamplesReport,
//   freeSamplesTargetReport,
//   marketingToolsReport,
//   monthlyOrdersReqs,
//   objectionsReport,
//   ordersReport,
//   productsMonthlySales,
//   medicalRepTargetReport,
//   targetGroupReport,
//   ProductsMonthlyExcel,
//   medicalRepTargetExcel,
//   targetGroupReportWithDate,
//   FundownloadVisitsWithDate,
// } from "../../../Redux/Actions/ActionReport";
// import { useSelectorCustom } from "../../General/GeneralComponent/useSelectorCustom";
// import { VariableStatistics } from "./VariableStatistics";
// import MultiSelectForReports from "../../GeneralReports/MultiSelectForReports";
// import { useEffect } from "react";
// import { format } from "date-fns";
// import nofilterr from "../../../images/icons/nofilter.png";
// import MedicalRepsReportsSelect from "../../General/GeneralComponent/MedicalRepsReportsSelect";
// import { useTranslation } from "react-i18next";
// function FilterStatistics({ t, langmiamed, params, excel }) {
//   const [startDate, setStartDate] = useState("");
//   const [endDate, setEndDate] = useState("");
//   const { i18n } = useTranslation();
//   let { dispatch } = AbstructVar();
//   const { LoadTargetTypeCustom, LoadMedicalRepCustom, LoadReportTypeCustom } =
//     FunctionsLoading();

//   useEffect(() => {
//     LoadReportTypeCustom();
//     LoadTargetTypeCustom();
//     LoadMedicalRepCustom();
//   }, []);
//   const {
//     target_types,
//     loadingtarget_type,
//     medicalRep,
//     loadingmedicalRep,
//     reportType,
//     loadingReportType,
//   } = useSelectorCustom();

//   const {
//     state,
//     setState,
//     handleChangeStatistics,
//     setLoad,
//     setIsFilter,
//     setLoadExport,
//   } = VariableStatistics();
//   const [selectedOptions, setSelectedOptions] = useState([]);
//   const [selectedTargets, setSelectedTargets] = useState([]);
//   const [selectedIds, setSelectedIds] = useState([]);

//   // const handleChange = (range) => {
//   //   const [startDatee, endDatee] = range;
//   //   setState((prevState) => ({
//   //     ...prevState,
//   //     startDate: startDatee,
//   //     endDate: endDatee,
//   //   }));
//   // };
//   const handleDateChange = (date, field, setState) => {
//     const formattedDate = format(date, "yyyy-MM-dd", {
//       timeZone: "yourTimeZoneHere",
//     });
//     setState((prevState) => ({
//       ...prevState,
//       [field]: formattedDate,
//     }));
//   };

//   const handleChangeFirstDate = (date) => {
//     handleDateChange(date, "startDate", setState);
//     setStartDate(date);
//   };

//   const handleChangeSecDate = (date) => {
//     handleDateChange(date, "endDate", setState);
//     setEndDate(date);
//   };
//   const unFilter = () => {
//     setStartDate(null);
//     setEndDate(null);
//     setSelectedOptions(null);
//     setSelectedTargets(null);
//     setSelectedIds(null);
//     switch (params) {
//       case "visits_frequeny_report":
//         setIsFilter(true);
//         dispatch(targetGroupReport("", "", [], "", setLoad));
//         break;
//       case "target_visits_report_with_date":
//         setIsFilter(true);
//         dispatch(targetGroupReportWithDate("", "", [], "", setLoad));
//         break;

//       case "target_visits_report":
//         setIsFilter(true);
//         dispatch(allVisitsReport("", "", [], "", setLoad));
//         break;

//       case "free_samples_summarized_report":
//         setIsFilter(true);
//         dispatch(freeSamplesReport("", "", [], "", setLoad));
//         break;
//       case "free_samples_detailed_report":
//         setIsFilter(true);
//         dispatch(freeSamplesTargetReport("", "", [], "", setLoad));
//         break;
//       case "marketing_tools_report":
//         setIsFilter(true);
//         dispatch(marketingToolsReport("", "", [], "", setLoad));
//         break;
//       case "monthly_orders_request_report":
//         setIsFilter(true);
//         dispatch(monthlyOrdersReqs("", "", [], "", setLoad));

//         break;
//       case "objection_rebort":
//         setIsFilter(true);
//         dispatch(objectionsReport("", "", [], "", setLoad));
//         break;
//       case "monthly_orders_report":
//         setIsFilter(true);
//         dispatch(ordersReport("", "", [], "", setLoad));
//         break;
//       case "products_monthly_salesReport":
//         setIsFilter(true);
//         dispatch(productsMonthlySales("", "", [], "", setLoad));
//         break;
//       case "medical_rep_target_report":
//         setIsFilter(true);
//         dispatch(medicalRepTargetReport("", "", [], "", setLoad));
//         break;

//       default:
//         break;
//     }
//   };

//   const filter = (event) => {
//     event.preventDefault();
//     switch (params) {
//       case "visits_frequeny_report":
//         setIsFilter(true);
//         dispatch(
//           targetGroupReport(
//             state.startDate,
//             state.endDate,
//             selectedIds,
//             state.target_type_id,
//             setLoad,
//           )
//         );
//         break;
//       case "target_visits_report":
//         setIsFilter(true);
//         dispatch(
//           allVisitsReport(
//             state.startDate,
//             state.endDate,
//             selectedIds,
//             state.target_type_id,
//             setLoad,
//           )
//         );
//         break;
//       case "target_visits_report_with_date":
//         setIsFilter(true);
//         dispatch(
//           targetGroupReportWithDate(
//             state.startDate,
//             state.endDate,
//             selectedIds,
//             state.target_type_id,
//             setLoad,
//           )
//         );
//         break;
//       case "free_samples_summarized_report":
//         setIsFilter(true);
//         dispatch(
//           freeSamplesReport(
//             state.startDate,
//             state.endDate,
//             selectedIds,
//             state.target_type_id,
//             setLoad,
//           )
//         );
//         break;
//       case "free_samples_detailed_report":
//         setIsFilter(true);
//         dispatch(
//           freeSamplesTargetReport(
//             state.startDate,
//             state.endDate,
//             selectedIds,
//             state.target_type_id,
//             setLoad,
//           )
//         );
//         break;
//       case "marketing_tools_report":
//         setIsFilter(true);
//         dispatch(
//           marketingToolsReport(
//             state.startDate,
//             state.endDate,
//             selectedIds,
//             state.target_type_id,
//             setLoad,
//           )
//         );
//         break;
//       case "monthly_orders_request_report":
//         setIsFilter(true);
//         dispatch(
//           monthlyOrdersReqs(
//             state.startDate,
//             state.endDate,
//             state.target_type_id,
//             state.report_type_id,
//             selectedIds,
//             setLoad,
//           )
//         );

//         break;
//       case "objection_rebort":
//         setIsFilter(true);
//         dispatch(
//           objectionsReport(
//             state.startDate,
//             state.endDate,
//             selectedIds,
//             state.target_type_id,
//             setLoad,
//           )
//         );
//         break;
//       case "monthly_orders_report":
//         setIsFilter(true);
//         dispatch(
//           ordersReport(
//             state.startDate,
//             state.endDate,
//             selectedIds,
//             state.target_type_id,
//             setLoad,
//           )
//         );
//         break;
//       case "products_monthly_salesReport":
//         setIsFilter(true);
//         dispatch(
//           productsMonthlySales(
//             state.startDate,
//             state.endDate,
//             selectedIds,
//             state.target_type_id,
//             setLoad,
//           )
//         );
//         break;
//       case "medical_rep_target_report":
//         setIsFilter(true);
//         dispatch(
//           medicalRepTargetReport(
//             state.startDate,
//             state.endDate,
//             selectedIds,
//             setLoad
//           )
//         );
//         break;
//       default:
//         break;
//     }
//   };
//   const exportToExcel = (event) => {
//     event.preventDefault();

//     switch (excel) {
//       case "visits_frequeny_report":
//         dispatch(
//           FundownloadTargetGroupVisits(
//             i18n.language,
//             state.startDate,
//             state.endDate,
//             selectedIds,
//             state.target_type_id,
//             setLoadExport
//           )
//         );
//         break;
//       case "target_visits_report":
//         dispatch(
//           FundownloadAllVisits(
//             i18n.language,
//             state.startDate,
//             state.endDate,
//             selectedIds,
//             state.target_type_id,
//             setLoadExport
//           )
//         );
//         break;
//       case "target_visits_report_with_date":
//         dispatch(
//           FundownloadVisitsWithDate(
//             i18n.language,
//             state.startDate,
//             state.endDate,
//             selectedIds,
//             state.target_type_id,
//             setLoadExport
//           )
//         );
//         break;
//       case "free_samples_summarized_report":
//         dispatch(
//           FundownloadFreeSamples(
//             i18n.language,
//             state.startDate,
//             state.endDate,
//             selectedIds,
//             state.target_type_id,
//             setLoadExport
//           )
//         );
//         break;
//       case "free_samples_detailed_report":
//         dispatch(
//           FundownloadFreeSamplesTarget(
//             i18n.language,
//             state.startDate,
//             state.endDate,
//             selectedIds,
//             state.target_type_id,
//             setLoadExport
//           )
//         );
//         break;

//       case "marketing_tools_report":
//         dispatch(
//           FundownloadMarketingTools(
//             i18n.language,
//             state.startDate,
//             state.endDate,
//             selectedIds,
//             state.target_type_id,
//             setLoadExport
//           )
//         );
//         break;

//       case "monthly_orders_request_report":
//         dispatch(
//           FundownloadReportOrdersReqs(
//             i18n.language,
//             state.startDate,
//             state.endDate,
//             selectedIds,
//             state.target_type_id,
//             setLoadExport
//           )
//         );
//         break;

//       case "objection_rebort":
//         dispatch(
//           FundownloadReportObjections(
//             i18n.language,
//             state.startDate,
//             state.endDate,
//             selectedIds,
//             state.target_type_id,
//             setLoadExport
//           )
//         );
//         break;
//       case "monthly_orders_report":
//         dispatch(
//           FundownloadReportOrders(
//             i18n.language,
//             state.startDate,
//             state.endDate,
//             selectedIds,
//             state.target_type_id,
//             setLoadExport
//           )
//         );
//         break;

//       case "products_monthly_salesReport":
//         dispatch(
//           ProductsMonthlyExcel(
//             i18n.language,
//             state.startDate,
//             state.endDate,
//             state.report_type_id,
//             selectedIds,
//             // state.target_type_id,
//             setLoadExport
//           )
//         );
//         break;
//       case "medical_rep_target_report":
//         dispatch(
//           medicalRepTargetExcel(
//             i18n.language,
//             state.startDate,
//             state.endDate,
//             selectedIds,
//             setLoadExport
//           )
//         );
//         break;

//       default:
//         break;
//     }
//   };

//   //   const [selectedOptionsName, setSelectedOptionsName] = useState([]);
//   //   useEffect(() => {
//   //     setSelectedOptionsName(reportType);
//   //   }, [reportType]);

//   //   const toggleOption = (optionId, optionName) => {
//   //     setSelectedOptions((prevOptions) => {
//   //       if (prevOptions.includes(optionId)) {
//   //         return prevOptions.filter((opt) => opt !== optionId);
//   //       } else {
//   //         return [...prevOptions, optionId];
//   //       }
//   //     });
//   //     setSelectedOptionsName((prevOptions) => {
//   //       if (prevOptions.includes(optionName)) {
//   //         return prevOptions.filter((opt) => opt !== optionName);
//   //       } else {
//   //         return [...prevOptions, optionName];
//   //       }
//   //     });
//   //   };

//   return (
//     <div className="newFilterrr" dir={langmiamed == "ar" ? "rtl" : "ltr"}>
//       <div className="d-flex gap-2 align-items-center">
//         <div className="">
//           <DatePicker
//             // selected={state.startDate}
//             selected={startDate}
//             onChange={handleChangeFirstDate}
//             // startDate={state.startDate}
//             // endDate={state.endDate}
//             // selectsRange
//             placeholderText={t("selectFirstDate")}
//             className="date_inn"
//           />
//         </div>
//         <div className="">
//           <DatePicker
//             selected={endDate}
//             // selected={state.startDate}
//             onChange={handleChangeSecDate}
//             // startDate={state.startDate}
//             // endDate={state.endDate}
//             // selectsRange
//             placeholderText={t("selectSecDate")}
//             className="date_inn"
//           />
//         </div>
//         <div className="">
//           <MedicalRepsReportsSelect
//             selectedOptions={selectedOptions}
//             setSelectedOptions={setSelectedOptions}
//             setSelectedIds={setSelectedIds}
//             testarr={medicalRep}
//             typeCss={true}
//             funb={LoadMedicalRepCustom}
//             load={loadingmedicalRep}
//           />
//         </div>
//         {/* target type */}

//         <div className="">

//           {(params === "target_group_visits" ||
//             params === "target_visits_report" ||
//             params === "visits_frequeny_report" ||
//             params === "free_samples_summarized_report" ||
//             params === "free_samples_detailed_report" ||
//             params === "marketing_tools_report" ||
//             params === "monthly_orders_request_report" ||
//             params === "objection_rebort" ||
//             params === "monthly_orders_report" ||
//             params === "target_visits_report_with_date") && (
//             <MultiSelectForReports
//               target_types={target_types}
//               setReportsIds={(value) =>
//                 handleChangeStatistics(value, "target_type_id")
//               }
//               selectedTargets={selectedTargets}
//               setSelectedTargets={setSelectedTargets}
//             />
//           )}
//         </div>

//         <button className="targetfilter_button" onClick={filter}>
//           {state.Load ? `${t("Loading ...")}` : `${t("filter")}`}
//         </button>
//         {state.isFilter && (
//           <button className="targetfilter_button" onClick={exportToExcel}>
//             {state.LoadExport ? `${t("Loading ...")}` : `${t("excel")}`}
//           </button>
//         )}
//         <img
//           onClick={unFilter}
//           title={t("unfilter")}
//           className="targetfilter_button_img"
//           src={nofilterr}
//           alt="un-filter-img"
//         />
//       </div>

//       {/* {(params === "target_group_visits" || params === "target_visits_report") && (
//         <div className="oneFilternew">
//           <SelectWithSearch
//             load={loadingtarget_type}
//             typeCss={true}
//             title={t("target_type")}
//             array={target_types}
//             setId={(value) => handleChangeStatistics(value, "target_type_id")}
//             funb={LoadTargetTypeCustom}
//             tt={true}
//           />
//         </div>
//       )} */}

//       {/* <div className="oneFilternew">
//         <SelectWithSearch
//           load={loadingReportType}
//           typeCss={true}
//           title={t("reportType")}
//           array={reportType}
//           setId={(value) => handleChangeStatistics(value, "report_type_id")}
//           funb={LoadReportTypeCustom}
//           tt={true}
//         />
//       </div> */}
//     </div>
//   );
// }

// export default FilterStatistics;

import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import MultiSelectCheckbox from "../../General/GeneralComponent/MultiSelectCheckbox";
import { FunctionsLoading } from "./FunctionsLoading";

import { AbstructVar } from "../../General/GeneralComponent/AbstructVar";
import {
  FundownloadAllVisits,
  FundownloadFreeSamples,
  FundownloadFreeSamplesTarget,
  FundownloadMarketingTools,
  FundownloadReportObjections,
  FundownloadReportOrders,
  FundownloadReportOrdersReqs,
  FundownloadReportTargetGroup,
  FundownloadTargetGroupVisits,
  allVisitsReport,
  freeSamplesReport,
  freeSamplesTargetReport,
  marketingToolsReport,
  monthlyOrdersReqs,
  objectionsReport,
  ordersReport,
  productsMonthlySales,
  medicalRepTargetReport,
  targetGroupReport,
  ProductsMonthlyExcel,
  medicalRepTargetExcel,
  targetGroupReportWithDate,
  FundownloadVisitsWithDate,
  hospitalReport,
  downloadHospitalReport,
  stockProductReport,
  downloadStockProductReport,
  stockMarkitingtReport,
  downloadStockMarkitingReport,
  stockMarkitingReport,
} from "../../../Redux/Actions/ActionReport";
import { useSelectorCustom } from "../../General/GeneralComponent/useSelectorCustom";
import { VariableStatistics } from "./VariableStatistics";
import MultiSelectForReports from "../../GeneralReports/MultiSelectForReports";
import { useEffect } from "react";
import { format } from "date-fns";
import nofilterr from "../../../images/icons/nofilter.png";
import MedicalRepsReportsSelect from "../../General/GeneralComponent/MedicalRepsReportsSelect";
import { useTranslation } from "react-i18next";
import {
  FilterStatisticTargetWithMidicalRep,
  loadStatisticTarget,
} from "../../../Redux/Actions/ActionStatistics";
function FilterStatistics({ t, langmiamed, params, excel }) {
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const { i18n } = useTranslation();
  let { dispatch } = AbstructVar();
  const { LoadTargetTypeCustom, LoadMedicalRepCustom, LoadReportTypeCustom } =
    FunctionsLoading();

  useEffect(() => {
    LoadReportTypeCustom();
    LoadTargetTypeCustom();
    LoadMedicalRepCustom();
  }, []);
  const {
    target_types,
    loadingtarget_type,
    medicalRep,
    loadingmedicalRep,
    reportType,
    loadingReportType,
  } = useSelectorCustom();

  const {
    state,
    setState,
    handleChangeStatistics,
    setLoad,
    setIsFilter,
    setLoadExport,
  } = VariableStatistics();
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectedTargets, setSelectedTargets] = useState([]);
  const [selectedIds, setSelectedIds] = useState([]);

  // const handleChange = (range) => {
  //   const [startDatee, endDatee] = range;
  //   setState((prevState) => ({
  //     ...prevState,
  //     startDate: startDatee,
  //     endDate: endDatee,
  //   }));
  // };
  const handleDateChange = (date, field, setState) => {
    const formattedDate = format(date, "yyyy-MM-dd", {
      timeZone: "yourTimeZoneHere",
    });
    setState((prevState) => ({
      ...prevState,
      [field]: formattedDate,
    }));
  };

  const handleChangeFirstDate = (date) => {
    handleDateChange(date, "startDate", setState);
    setStartDate(date);
  };

  const handleChangeSecDate = (date) => {
    handleDateChange(date, "endDate", setState);
    setEndDate(date);
  };
  const unFilter = () => {
    setStartDate("");
    setEndDate("");
    setSelectedOptions(null);
    setSelectedTargets(null);
    setSelectedIds(null);
    setState((prevState) => ({
      ...prevState,
      startDate: "",
      endDate: "",
      target_type_id: [],
      report_type_id: [],
      setSelectedIds: [],
    }));
    setIsFilter(false);
    // switch (params) {
    //   case "visits_frequeny_report":
    //     setIsFilter(true);
    //     dispatch(targetGroupReport("", "", [], "", setLoad));
    //     break;
    //   case "target_visits_report_with_date":
    //     setIsFilter(true);
    //     dispatch(targetGroupReportWithDate("", "", [], "", setLoad));
    //     break;

    //   case "target_visits_report":
    //     setIsFilter(true);
    //     dispatch(allVisitsReport("", "", [], "", setLoad));
    //     break;

    //   case "free_samples_summarized_report":
    //     setIsFilter(true);
    //     dispatch(freeSamplesReport("", "", [], "", setLoad));
    //     break;
    //   case "free_samples_detailed_report":
    //     setIsFilter(true);
    //     dispatch(freeSamplesTargetReport("", "", [], "", setLoad));
    //     break;
    //   case "marketing_tools_report":
    //     setIsFilter(true);
    //     dispatch(marketingToolsReport("", "", [], "", setLoad));
    //     break;
    //   case "monthly_orders_request_report":
    //     setIsFilter(true);
    //     dispatch(monthlyOrdersReqs("", "", [], "", setLoad));

    //     break;
    //   case "objection_rebort":
    //     setIsFilter(true);
    //     dispatch(objectionsReport("", "", [], "", setLoad));
    //     break;
    //   case "monthly_orders_report":
    //     setIsFilter(true);
    //     dispatch(ordersReport("", "", [], "", setLoad));
    //     break;
    //   case "products_monthly_salesReport":
    //     setIsFilter(true);
    //     dispatch(productsMonthlySales("", "", [], "", setLoad));
    //     break;
    //   case "medical_rep_target_report":
    //     setIsFilter(true);
    //     dispatch(medicalRepTargetReport("", "", [], "", setLoad));
    //     break;

    //   default:
    //     break;
    // }
  };

  const filter = (event) => {
    event.preventDefault();
    switch (params) {
      case "get_products_stock_report":
        setIsFilter(true);
        dispatch(stockProductReport(selectedIds, setLoad));
        break;
      case "get_Statistc_With_Search":
        setIsFilter(true);
        dispatch(FilterStatisticTargetWithMidicalRep(selectedIds, setLoad));
        break;
      case "get_markitings_stock_report":
        setIsFilter(true);
        dispatch(stockMarkitingReport(selectedIds, setLoad));
        break;
      case "hospital":
        setIsFilter(true);
        dispatch(
          hospitalReport(
            state.startDate,
            state.endDate,
            selectedIds,
            state.target_type_id,
            setLoad
          )
        );
        break;
      case "visits_frequeny_report":
        setIsFilter(true);
        dispatch(
          targetGroupReport(
            state.startDate,
            state.endDate,
            selectedIds,
            state.target_type_id,
            setLoad
          )
        );
        break;
      case "target_visits_report":
        setIsFilter(true);
        dispatch(
          allVisitsReport(
            state.startDate,
            state.endDate,
            selectedIds,
            state.target_type_id,
            setLoad
          )
        );
        break;
      case "target_visits_report_with_date":
        setIsFilter(true);
        dispatch(
          targetGroupReportWithDate(
            state.startDate,
            state.endDate,
            selectedIds,
            state.target_type_id,
            i18n.language,

            setLoad
          )
        );
        break;
      case "free_samples_summarized_report":
        setIsFilter(true);
        dispatch(
          freeSamplesReport(
            state.startDate,
            state.endDate,
            selectedIds,
            state.target_type_id,
            setLoad
          )
        );
        break;
      case "free_samples_detailed_report":
        setIsFilter(true);
        dispatch(
          freeSamplesTargetReport(
            state.startDate,
            state.endDate,
            selectedIds,
            state.target_type_id,
            setLoad
          )
        );
        break;
      case "marketing_tools_report":
        setIsFilter(true);
        dispatch(
          marketingToolsReport(
            state.startDate,
            state.endDate,
            selectedIds,
            state.target_type_id,
            setLoad
          )
        );
        break;
      case "monthly_orders_request_report":
        setIsFilter(true);
        dispatch(
          monthlyOrdersReqs(
            state.startDate,
            state.endDate,
            state.target_type_id,
            state.report_type_id,
            selectedIds,
            setLoad
          )
        );

        break;
      case "objection_rebort":
        setIsFilter(true);
        dispatch(
          objectionsReport(
            state.startDate,
            state.endDate,
            selectedIds,
            state.target_type_id,
            setLoad
          )
        );
        break;
      case "monthly_orders_report":
        setIsFilter(true);
        dispatch(
          ordersReport(
            state.startDate,
            state.endDate,
            selectedIds,
            state.target_type_id,
            setLoad
          )
        );
        break;
      case "products_monthly_salesReport":
        setIsFilter(true);
        dispatch(
          productsMonthlySales(
            state.startDate,
            state.endDate,
            selectedIds,
            state.target_type_id,
            setLoad
          )
        );
        break;
      case "medical_rep_target_report":
        setIsFilter(true);
        dispatch(
          medicalRepTargetReport(
            state.startDate,
            state.endDate,
            selectedIds,
            setLoad
          )
        );
        break;
      default:
        break;
    }
  };
  const exportToExcel = (event) => {
    event.preventDefault();

    switch (excel) {
      case "get_products_stock_report":
        setIsFilter(true);
        dispatch(
          downloadStockProductReport(i18n.language, selectedIds, setLoad)
        );
        break;
      case "get_markitings_stock_report":
        setIsFilter(true);
        dispatch(
          downloadStockMarkitingReport(i18n.language, selectedIds, setLoad)
        );
        break;
      case "visits_frequeny_report":
        dispatch(
          FundownloadTargetGroupVisits(
            i18n.language,
            state.startDate,
            state.endDate,
            selectedIds,
            state.target_type_id,
            setLoadExport
          )
        );
        break;
      case "hospital":
        setIsFilter(true);
        dispatch(
          downloadHospitalReport(
            state.startDate,
            state.endDate,
            selectedIds,
            state.target_type_id,
            setLoad
          )
        );
        break;
      case "target_visits_report":
        dispatch(
          FundownloadAllVisits(
            i18n.language,
            state.startDate,
            state.endDate,
            selectedIds,
            state.target_type_id,
            setLoadExport
          )
        );
        break;
      case "target_visits_report_with_date":
        dispatch(
          FundownloadVisitsWithDate(
            i18n.language,
            state.startDate,
            state.endDate,
            selectedIds,
            state.target_type_id,
            setLoadExport
          )
        );
        break;
      case "free_samples_summarized_report":
        dispatch(
          FundownloadFreeSamples(
            i18n.language,
            state.startDate,
            state.endDate,
            selectedIds,
            state.target_type_id,
            setLoadExport
          )
        );
        break;
      case "free_samples_detailed_report":
        dispatch(
          FundownloadFreeSamplesTarget(
            i18n.language,
            state.startDate,
            state.endDate,
            selectedIds,
            state.target_type_id,
            setLoadExport
          )
        );
        break;

      case "marketing_tools_report":
        dispatch(
          FundownloadMarketingTools(
            i18n.language,
            state.startDate,
            state.endDate,
            selectedIds,
            state.target_type_id,
            setLoadExport
          )
        );
        break;

      case "monthly_orders_request_report":
        dispatch(
          FundownloadReportOrdersReqs(
            i18n.language,
            state.startDate,
            state.endDate,
            selectedIds,
            state.target_type_id,
            setLoadExport
          )
        );
        break;

      case "objection_rebort":
        dispatch(
          FundownloadReportObjections(
            i18n.language,
            state.startDate,
            state.endDate,
            selectedIds,
            state.target_type_id,
            setLoadExport
          )
        );
        break;
      case "monthly_orders_report":
        dispatch(
          FundownloadReportOrders(
            i18n.language,
            state.startDate,
            state.endDate,
            selectedIds,
            state.target_type_id,
            setLoadExport
          )
        );
        break;

      case "products_monthly_salesReport":
        dispatch(
          ProductsMonthlyExcel(
            i18n.language,
            state.startDate,
            state.endDate,
            state.report_type_id,
            selectedIds,
            // state.target_type_id,
            setLoadExport
          )
        );
        break;
      case "medical_rep_target_report":
        dispatch(
          medicalRepTargetExcel(
            i18n.language,
            state.startDate,
            state.endDate,
            selectedIds,
            setLoadExport
          )
        );
        break;

      default:
        break;
    }
  };

  //   const [selectedOptionsName, setSelectedOptionsName] = useState([]);
  //   useEffect(() => {
  //     setSelectedOptionsName(reportType);
  //   }, [reportType]);

  //   const toggleOption = (optionId, optionName) => {
  //     setSelectedOptions((prevOptions) => {
  //       if (prevOptions.includes(optionId)) {
  //         return prevOptions.filter((opt) => opt !== optionId);
  //       } else {
  //         return [...prevOptions, optionId];
  //       }
  //     });
  //     setSelectedOptionsName((prevOptions) => {
  //       if (prevOptions.includes(optionName)) {
  //         return prevOptions.filter((opt) => opt !== optionName);
  //       } else {
  //         return [...prevOptions, optionName];
  //       }
  //     });
  //   };

  return (
    <div className="newFilterrr" dir={langmiamed == "ar" ? "rtl" : "ltr"}>
      <div className="d-flex gap-2 align-items-center">
        {params === "get_products_stock_report" ||
        params === "get_Statistc_With_Search" ||
        params === "get_markitings_stock_report" ? null : (
          <>
            <div className="">
              <DatePicker
                // selected={state.startDate}
                selected={startDate}
                onChange={handleChangeFirstDate}
                // startDate={state.startDate}
                // endDate={state.endDate}
                // selectsRange
                placeholderText={t("selectFirstDate")}
                className="date_inn"
              />
            </div>
            <div className="">
              <DatePicker
                selected={endDate}
                // selected={state.startDate}
                onChange={handleChangeSecDate}
                // startDate={state.startDate}
                // endDate={state.endDate}
                // selectsRange
                placeholderText={t("selectSecDate")}
                className="date_inn"
              />
            </div>
          </>
        )}
        <div className="">
          <MedicalRepsReportsSelect
            selectedOptions={selectedOptions}
            setSelectedOptions={setSelectedOptions}
            setSelectedIds={setSelectedIds}
            testarr={medicalRep}
            typeCss={true}
            funb={LoadMedicalRepCustom}
            load={loadingmedicalRep}
          />
        </div>
        {/* target type */}

        <div className="">
          {(params === "target_group_visits" ||
            params === "target_visits_report" ||
            params === "visits_frequeny_report" ||
            params === "free_samples_summarized_report" ||
            params === "free_samples_detailed_report" ||
            params === "marketing_tools_report" ||
            params === "monthly_orders_request_report" ||
            params === "objection_rebort" ||
            params === "monthly_orders_report" ||
            params === "hospital" ||
            params === "target_visits_report_with_date") && (
            <MultiSelectForReports
              target_types={target_types}
              setReportsIds={(value) =>
                handleChangeStatistics(value, "target_type_id")
              }
              selectedTargets={selectedTargets}
              setSelectedTargets={setSelectedTargets}
            />
          )}
        </div>

        <button className="targetfilter_button" onClick={filter}>
          {state.Load ? `${t("Loading ...")}` : `${t("filter")}`}
        </button>
        {params === "get_Statistc_With_Search" ? null : (
          <>
            {state.isFilter && (
              <button className="targetfilter_button" onClick={exportToExcel}>
                {state.LoadExport ? `${t("Loading ...")}` : `${t("excel")}`}
              </button>
            )}
            <img
              onClick={unFilter}
              title={t("unfilter")}
              className="targetfilter_button_img"
              src={nofilterr}
              alt="un-filter-img"
            />
          </>
        )}
      </div>

      {/* {(params === "target_group_visits" || params === "target_visits_report") && (
        <div className="oneFilternew">
          <SelectWithSearch
            load={loadingtarget_type}
            typeCss={true}
            title={t("target_type")}
            array={target_types}
            setId={(value) => handleChangeStatistics(value, "target_type_id")}
            funb={LoadTargetTypeCustom}
            tt={true}
          />
        </div>
      )} */}

      {/* <div className="oneFilternew">
        <SelectWithSearch
          load={loadingReportType}
          typeCss={true}
          title={t("reportType")}
          array={reportType}
          setId={(value) => handleChangeStatistics(value, "report_type_id")}
          funb={LoadReportTypeCustom}
          tt={true}
        />
      </div> */}
    </div>
  );
}

export default FilterStatistics;

import React, { useEffect, useState } from "react";
import Page_Header from "../General/Page_Header";
import "./Main.css";
import { useSelector } from "react-redux";
import { FilterStatisticTargetWithMidicalRep, loadStatisticTarget } from "../../Redux/Actions/ActionStatistics";
// import StatisticsWorkPlans from "./Statistics/StatisticsWorkPlans";
import ChartComponent from "../WorkPlans/VisitesWorkPlan/ChartComponent";
import { AbstructVar } from "../General/GeneralComponent/AbstructVar";
import { AbstructLang } from "../General/GeneralComponent/AbstructLang";
import Page_General_Statistics from "./CardsMain/Page_General_Statistics";
import Scientific_Office_Statistics from "./CardsMain/Scientific_Office_Statistics";
import FilterStatistics from "./Statistics/FilterStatistics";

function MainWithSearch({ isDashboardPage , t, navigate, setopenInternet }) {
  let { langmiamed, dispatch } = AbstructVar();
  const { translate } = AbstructLang();
  const statisticsTarget = useSelector(
    (state) => state.statisticss.statisticsTargetWithSearch
  );
 // Add loading state
 const [loading, setLoading] = useState(false); // <-- useState hook

 useEffect(() => {
   window.scrollTo(0, 0);
   // Pass setLoading to handle loading state
   dispatch(FilterStatisticTargetWithMidicalRep(null, setLoading));
 }, [dispatch]);
  return (
    <div className={langmiamed == "ar" ? "main-content_ar" : "main-content_en"}>
         
      <main>
        {isDashboardPage && (
          <Page_Header
            langmiamed={langmiamed}
            header={translate("dashboard")}
            path={`${translate("home")} / ${translate("dashboard")} `}
          />
        )}

        <div
          //   className={isDashboardPage ? "report-page-content" : "page-content"}
          style={{
            padding: "1.3rem 1rem", // corrected syntax for padding
            background: "white",
            margin: isDashboardPage ? "-35px 60px 20px 60px" : "0",
            borderRadius: "30px", // corrected syntax for borderRadius
            minHeight: "90vh", // corrected syntax for minHeight
            /*overflow: auto;*/
            position: "relative", // corrected syntax for position
          }}
        >
             <FilterStatistics
        t={t}
        langmiamed={langmiamed}
        params="get_Statistc_With_Search"
        excel="get_Statistc_With_Search"
        
      />
          {isDashboardPage && (
            <>
              <h4
                className={`workplanrepo ${
                  langmiamed == "ar" ? "textRight" : ""
                }`}
              >
                {translate("General_statistics")}
              </h4>
              <Page_General_Statistics
                langmiamed={langmiamed}
                dispatch={dispatch}
                translate={translate}
              />
            </>
          )}
          {isDashboardPage && (
            <>
              <h4
                className={`workplanrepo ${
                  langmiamed == "ar" ? "textRight" : ""
                }`}
              >
                {translate("Scientific_Office")}
              </h4>
              {/* <FilterStatistics
            t={translate}
            langmiamed={langmiamed}
            params="external_visit"
          /> */}
              <Scientific_Office_Statistics
                t={translate}
                langmiamed={langmiamed}
                dispatch={dispatch}
              />
            </>
          )}

          <div>
            {/* <StatisticsWorkPlans t={translate} langmiamed={langmiamed} /> */}

            <h4 className={`workplanrepo ${langmiamed == 'ar' ? "textRight" : ""}`} >{t('workplanrepo')}</h4>
      {/* <FilterStatistics t={t} langmiamed={langmiamed} params='work_plan' /> */}
      <div className={`bottom_analytics `} dir={langmiamed == 'ar' ? "rtl" : "ltr"} >
        <div className={`one_bottom_analytics ${langmiamed === 'ar' ? "margin_left_30" : "margin_right_30"} `} >
          <span>{t('alltaskscount')}</span>
          <span className='alltaskscount' >{statisticsTarget.all_tasks_count}</span>
        </div>
        <div className={`one_bottom_analytics ${langmiamed === 'ar' ? "margin_left_30" : "margin_right_30"} `}>
          <span>{t('delivered')}</span>
          <span className='alltaskscount' >{statisticsTarget.delivered_product_count}</span>
        </div>
      </div>
      <div className='allcharts'  style={{ marginTop: "10px" }} >
        <div className='onechart' >
          <ChartComponent langmiamed={langmiamed} color1="#50b798" num1={statisticsTarget.visits_count} num2={statisticsTarget.not_visits_count} name1={t('visits_count')} name2={t('not_visits_count')} />
        </div>
        <div className='onechart' >
          <ChartComponent langmiamed={langmiamed} color1='#4285C5' num1={statisticsTarget.marketing_tools_count} num2={statisticsTarget.medicine_samples_count} name1={t('marketing_tools_count')} name2={t('medicine_samples_count')} />
        </div>
        <div className='onechart' >
          <ChartComponent langmiamed={langmiamed} color1='#f67eb4' num1={statisticsTarget.visits_match_count} num2={statisticsTarget.visits_not_match_count} name1={t('visits_match_count')} name2={t('visits_not_match_count')} />
        </div>
      </div>

          </div>
          <h4
            className={`workplanrepo ${langmiamed == "ar" ? "textRight" : ""}`}
          >
            {translate("target_group_report")}
          </h4>
          {/* <FilterStatistics
            t={translate}
            langmiamed={langmiamed}
            params="target_type"
          /> */}
          <div
            className="div_static_target"
            dir={langmiamed == "ar" ? "rtl" : "ltr"}
          >
            <div className="div_static_target_card">
              <div
                className={`one_bottom_analytics1 ${
                  langmiamed === "ar" ? "margin_left_30" : "margin_right_30"
                } `}
              >
                <span>{translate("visitesCount")}</span>
                <span className="alltaskscount">
                  {statisticsTarget?.visits_count}
                </span>
              </div>
              <div
                className={`one_bottom_analytics1 ${
                  langmiamed === "ar" ? "margin_left_30" : "margin_right_30"
                } `}
              >
                <span>{translate("marketing_tools_count")}</span>
                <span className="alltaskscount">
                  {statisticsTarget?.marketing_tools_count}
                </span>
              </div>
             
              <div
                className={`one_bottom_analytics1 ${
                  langmiamed === "ar" ? "margin_left_30" : "margin_right_30"
                } `}
              >
                <span>{translate("deleverdMarkiting")}</span>
                <span className="alltaskscount">
                  {statisticsTarget?.delivered_marketing_count}
                </span>
              </div>
             
              <div
                className={`one_bottom_analytics1 ${
                  langmiamed === "ar" ? "margin_left_30" : "margin_right_30"
                } `}
              >
                <span>{translate("delivered")}</span>
                <span className="alltaskscount">
                  {statisticsTarget?.delivered_product_count}
                </span>
              </div>
            </div>
            <div className="div_static_target_chart">
              <div className="onechart" style={{ width: "100%" }}>
                <ChartComponent
                  langmiamed={langmiamed}
                  color1="#50b798"
                  num1={statisticsTarget?.marketing_tools_count}
                  num2={statisticsTarget?.medicine_samples_count}
                  name1={translate("marketing_tools_count")}
                  name2={translate("medicine_samples_count")}
                />
              </div>
            </div>
          </div>
        </div>
      </main>
 </div>

  );
}

export default MainWithSearch;

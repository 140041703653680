// import React from "react";
// import { useTranslation } from "react-i18next";
// import Select from "react-select";
// import { AbstructVar } from "../General/GeneralComponent/AbstructVar";

// const MultiSelectForReports = ({
//   target_types,
//   setReportsIds,
//   selectedTargets,
//   setSelectedTargets,
// }) => {
//   let { t } = useTranslation();
//   let { langmiamed } = AbstructVar();
//   const customStyles = {
//     control: (provided) => ({
//       ...provided,
//       borderRadius: "8px",
//       borderColor: "#ccc",
//       boxShadow: "none",
//     }),
//     placeholder: (provided) => ({
//       ...provided,
//       color: "gray",
//       textAlign: langmiamed === "ar" ? "right" : "left",
//       fontFamily: "GE SS TWO",
//     }),
//     multiValue: (provided) => ({
//       ...provided,
//       backgroundColor: "#f0f0f0",
//       borderRadius: "4px",
//       padding: "2px 4px",
//     }),
//     option: (provided, state) => ({
//       ...provided,

//       fontFamily: "GE SS TWO",
//     }),
//   };

//   const targetTypeOptions = target_types?.map((el) => {
//     return {
//       label: t(el.name),
//       value: el.id,
//     };
//   });

//   const onChange = (selectedOptions) => {
//     const ids = selectedOptions.map((option) => option.value);
//     setReportsIds(ids);
//     setSelectedTargets(selectedOptions);
//   };

//   return (
//     <div>
//       <Select
//         options={targetTypeOptions}
//         isMulti
//         value={selectedTargets}
//         onChange={(selectedValues) => {
//           const ids = selectedValues.map((option) => option.value);
//           setReportsIds(ids);
//           setSelectedTargets(selectedValues);
//         }}
//         placeholder={t("Choose Target Types")}
//         styles={{
//           placeholder: (provided) => ({
//             ...provided,
//             color: "gray",
//             textAlign: langmiamed === "ar" ? "right" : "left",
//             fontFamily: "Open Sans, sans-serif",
//           }),
//           menu: (provided) => ({
//             ...provided,
//             textAlign: langmiamed === "ar" ? "right" : "left",
//           }),
//           control: (provided) => ({
//             ...provided,
//             fontFamily: "Open Sans, sans-serif",
//             fontSize: "13px !important",
//           }),
//           option: (provided, state) => ({
//             ...provided,
//             fontSize: "13px !important",

//             // fontFamily: "GE SS TWO",
//           }),
//         }}
//       />
//     </div>
//   );
// };

// export default MultiSelectForReports;


import React from "react";
import { useTranslation } from "react-i18next";
import Select, { components } from "react-select";
import { AbstructVar } from "../General/GeneralComponent/AbstructVar";

// Custom Option component to include a checkbox before the label
const CheckboxOption = ({ children, ...props }) => {
  return (
    <components.Option {...props}>
      <input
        type="checkbox"
        checked={props.isSelected}
        onChange={() => null} // No-op, handled by react-select
        style={{ marginRight: 8 }}
      />
      {children}
    </components.Option>
  );
};

const MultiSelectForReports = ({
  target_types,
  setReportsIds,
  selectedTargets,
  setSelectedTargets,
}) => {
  let { t } = useTranslation();
  let { langmiamed } = AbstructVar();

  const customStyles = {
    control: (provided) => ({
      ...provided,
      borderRadius: "8px",
      borderColor: "#ccc",
      boxShadow: "none",
      fontFamily: "Open Sans, sans-serif",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "gray",
      textAlign: langmiamed === "ar" ? "right" : "left",
    }),
    multiValue: (provided) => ({
      ...provided,
      backgroundColor: "#f0f0f0",
      borderRadius: "4px",
      padding: "2px 4px",
    }),
    option: (provided) => ({
      ...provided,
      fontSize: "13px !important",
      textAlign: langmiamed === "ar" ? "right" : "left",
    }),
    menu: (provided) => ({
      ...provided,
      textAlign: langmiamed === "ar" ? "right" : "left",
    }),
  };

  // Convert the target_types to a format react-select can use
  const targetTypeOptions = target_types?.map((el) => ({
    label: t(el.name),
    value: el.id,
  }));

  // Handle changes in the selection
  const onChange = (selectedOptions) => {
    const ids = selectedOptions.map((option) => option.value);
    setReportsIds(ids);
    setSelectedTargets(selectedOptions);
  };

  return (
    <div>
      <Select
        options={targetTypeOptions}
        isMulti
        value={selectedTargets}
        onChange={onChange}
        closeMenuOnSelect={false} // Prevent dropdown from closing
        hideSelectedOptions={false} // Show selected options with checkboxes
        components={{ Option: CheckboxOption }} // Use custom checkbox option
        placeholder={t("Choose Target Types")}
        isSearchable // Enable search functionality
        styles={customStyles} // Apply custom styles
      />
    </div>
  );
};

export default MultiSelectForReports;

import * as types from "../ActionType";
import axios from "axios";
import {
  notifyError,
  notifysuccess,
  notifyErrorNetWork,
} from "../../Components/General/Notification";
import { base_url } from "../../Components/General/Notification";
import { hasRout } from "../../Components/General/UseRout";

const LoginAdmin = () => {
  return {
    type: types.LOGIN,
  };
};

const LogoutAdmin = () => {
  return {
    type: types.LOGOUT,
  };
};
const saveFCM = () => {
  return {
    type: types.SAVE_FCM,
  };
};
const getUsers = (users) => {
  return {
    type: types.GET_ALL_USERS,
    payload: users,
  };
};
const getLasts = (lasts) => {
  return {
    type: types.GET_ALL_LASTS,
    payload: lasts,
  };
};
const addUser = () => {
  return {
    type: types.ADD_USER,
  };
};

const delUser = () => {
  return {
    type: types.DELETE_USER,
  };
};

const editUser = () => {
  return {
    type: types.EDIT_USER,
  };
};

const getseniorsuper = (senior_supervisor) => {
  return {
    type: types.GET_SENIOR_SUPERVISOR,
    payload: senior_supervisor,
  };
};
const emptyGeneral = (empty, typee) => {
  return {
    type: typee,
    payload: empty,
  };
};
const getProfile = (profile) => {
  return {
    type: types.GET_INFORMATION_PROFILE,
    payload: profile,
  };
};

const getPerr = (permissionss) => {
  return {
    type: types.GET_ALL_PERMISSIONS,
    payload: permissionss,
  };
};
const getNot = (notify) => {
  return {
    type: types.GET_NOTIFICATIONS,
    payload: notify,
  };
};
export const AuthLogin = (email, password, navigate, setLoad) => {
  return function (dispatch) {
    setLoad(true);
    axios
      .post(`${base_url}api/auth/login?email=${email}&password=${password}`)
      .then((res) => {
        if (res.data.status == "Success") {
          localStorage.setItem("tokenMiamed", res.headers.authorization);
          localStorage.setItem("exitFCM", "FCM");
          dispatch(LoginAdmin());
          setLoad(false);
          navigate("/");
          window.location.reload();
        }
      })
      .catch((err) => {
        notifyError(err.response.data.message);
        setLoad(false);
      });
  };
};

export const AuthLogout = (navigate, setLoad) => {
  return function (dispatch) {
    setLoad(true);
    let token = localStorage.getItem("tokenMiamed");
    axios
      .post(
        `${base_url}api/auth/logout`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        if (res.data.status == "Success") {
          dispatch(LogoutAdmin());
          setLoad(false);
          localStorage.removeItem("tokenMiamed");
          localStorage.setItem("exitFCM", "");
          navigate("/");
          window.location.reload();
        }
      })
      .catch((err) => {
        notifyError(err.response.data.message);
        setLoad(false);
      });
  };
};

export const SendFCM = (FCMToken) => {
  return function (dispatch) {
    let token = localStorage.getItem("tokenMiamed");
    axios
      .post(
        `${base_url}api/auth/save-fcm-token?fcm_token=${FCMToken}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        if (res.data.status == "Success") {
          dispatch(saveFCM());
        }
      })
      .catch((err) => {
      });
  };
};

export const loadUsers = (navigate, setopenInternet) => {
  return function (dispatch) {
    let token = localStorage.getItem("tokenMiamed");
    axios
      .get(`${base_url}api/users`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        dispatch(getUsers(res.data.data));
      })
      .catch((err) => {
        hasRout(err.response.status, navigate);
        if (err.message == "Network Error" || err.message) {
          setopenInternet(true);
        }
      });
  };
};

export const loadLast = (navigate, setopenInternet) => {
  return function (dispatch) {
    let token = localStorage.getItem("tokenMiamed");
    axios
      .get(`${base_url}api/last_login_report`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        dispatch(getLasts(res.data.data));
      })
      .catch((err) => {
        hasRout(err.response.status, navigate);
        if (err.message == "Network Error" || err.message) {
          setopenInternet(true);
        }
      });
  };
};

export const PostUser = (
  first_name,
  last_name,
  email,
  password,
  address,
  phone1,
  role,
  gender,
  city_id,
  related_id,
  setLoad,
  setOpen
) => {
  return function (dispatch) {
    setLoad(true);
    let token = localStorage.getItem("tokenMiamed");
    let phone = parseInt(phone1);
    axios
      .post(
        `${base_url}api/users`,
        {
          first_name,
          last_name,
          email,
          password,
          address,
          phone,
          role,
          gender,
          city_id,
          related_id,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        dispatch(addUser());
        if (res.data.status == "Success") {
          notifysuccess(res.data.message);
          dispatch(loadUsers());
          setLoad(false);
          setOpen(false)
        }
      })
      .catch((err) => {
        notifyError(err.response.data.message);
        setLoad(false);
      });
  };
};

export const deleteUser = (id, setdeletee, setloadDel) => {
  return function (dispatch) {
    let token = localStorage.getItem("tokenMiamed");
    setloadDel(true);
    axios
      .delete(`${base_url}api/users/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.data.status == "Success") {
          notifysuccess(res.data.message);
          dispatch(delUser());
          dispatch(loadUsers());
          setdeletee(false);
          setloadDel(false);
        }
      })
      .catch((err) => {
        setdeletee(false);
        notifyError(err.response.data.message);
        setloadDel(false);
      });
  };
};

export const postEditUser = (
  id,
  first_name,
  last_name,
  email,
  password,
  address,
  phone,
  role,
  gender,
  city_id,
  related_id,
  setLoad,
  setOpen
) => {
  return function (dispatch) {
    setLoad(true);
    let token = localStorage.getItem("tokenMiamed");
    if (password == "") {
      axios
        .put(
          `${base_url}api/users/${id}`,
          {
            first_name,
            last_name,
            email,
            address,
            phone,
            role,
            gender,
            city_id,
            related_id,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
            dispatch(editUser());
          if (res.data.status == "Success") {
            notifysuccess(res.data.message);
            dispatch(loadUsers());
            setLoad(false);
            setOpen(false);
          }
        })
        .catch((err) => {
            notifyError(err.response.data.message);
          setLoad(false);
        });
    } else {
      axios
        .put(
          `${base_url}api/users/${id}`,
          {
            first_name,
            last_name,
            email,
            password,
            address,
            phone,
            role,
            gender,
            city_id,
            related_id,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
            dispatch(editUser());
          if (res.data.status == "Success") {
            notifysuccess(res.data.message);
            dispatch(loadUsers());
            setLoad(false);
          }
        })
        .catch((err) => {
            notifyError(err.response.data.message);
          setLoad(false);
        });
    }
  };
};
export const loadSeniorSupervisor = (name, setLoad) => {
  return function (dispatch) {
    setLoad(true);
    let token = localStorage.getItem("tokenMiamed");
    axios
      .get(`${base_url}api/users?role=${name}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        dispatch(getseniorsuper(res.data.data));
        setLoad(false);
      })
      .catch((err) => {
        setLoad(false);
      });
  };
};

export const emptySenior = () => {
  return function (dispatch) {
    let emptyy = [];
    dispatch(emptyGeneral(emptyy, types.EMPTY_SENIOR_SUPERVISOR));
  };
};

export const loadProfile = (navigate) => {
  return function (dispatch) {
    let token = localStorage.getItem("tokenMiamed");
    axios
      .get(`${base_url}api/auth/profile-details`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        dispatch(getProfile(res.data.data));
      })
      .catch((err) => {
        if (err.response.data.returnedCode == 403) {
          localStorage.removeItem("tokenMiamed");
          navigate("/");
          window.location.reload();
        }
      });
  };
};

export const loadAllPermissions = (setopenInternet) => {
  return function (dispatch) {
    let token = localStorage.getItem("tokenMiamed");
    axios
      .get(`${base_url}api/roles/permissions/user`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        dispatch(getPerr(res.data.data));
      })
      .catch((err) => {
        if (err.message == "Network Error" || err.message) {
          setopenInternet(true);
        }
      });
  };
};


export const loadNotification = () => {
  return function (dispatch) {
    let token = localStorage.getItem("tokenMiamed");
    return axios 
      .get(`${base_url}api/notifications/user`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        dispatch(getNot(res.data.data));
      })
      .catch((err) => {
      });
  };
};
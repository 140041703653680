import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { loadProduct } from "../../../Redux/Actions/ActionProduct";
import InnerLoader from "../../General/InnerLoader";
import useClickOutside from "../../../useClickOutside";
import {
  assignWorkPlanMar,
  emptyUnassignMar,
  loadUnassignMar,
} from "../../../Redux/Actions/ActionWorkPlanMarketing";
import { CheckFont } from "../../General/GeneralComponent/CheckLang";

function MultiSelectStock({ work_plan_id, t, langmiamed, setOpen }) {
  const [openInternet, setopenInternet] = useState(false);
  const [batch, setBatch] = useState(null);
  let navigate = useNavigate();
  let dispatch = useDispatch();
  const [Load, setLoad] = useState(false);
  const [arrID, setarrID] = useState([]);
  const [showQuantity, setShowQuantity] = useState({});

  const Unassign_marketing = useSelector(
    (state) => state.Marketing_WorkPlan.Unassign_Marketing
  );

  const loadingUnassign_marketing = useSelector(
    (state) => state.Marketing_WorkPlan.loadingUnassign_Marketing
  );
  useEffect(() => {
    dispatch(loadUnassignMar(work_plan_id));
    return () => {
      dispatch(emptyUnassignMar());
    };
  }, []);

  const handleCheckboxChange = (productId) => {
    const isProductSelected = arrID.some((item) => item.id === productId);

    if (isProductSelected) {
      setarrID((prevArr) => prevArr.filter((item) => item.id !== productId));
      setShowQuantity((prevShowQuantity) => ({
        ...prevShowQuantity,
        [productId]: false,
      }));
    } else {
      setarrID((prevArr) => [...prevArr, { id: productId, quantity: 0 }]);
      setShowQuantity((prevShowQuantity) => ({
        ...prevShowQuantity,
        [productId]: true,
      }));
    }
  };

  const handleQuantityChange = (productId, newQuantity) => {
    setarrID((prevArr) =>
      prevArr.map((item) =>
        item.id === productId ? { ...item, quantity: newQuantity } : item
      )
    );
  };
  const handleBatchChange = (productId, batch) => {
    setarrID((prevArr) =>
      prevArr.map((item) =>
        item.id === productId ? { ...item, batch_number: batch } : item
      )
    );
  };

  let domNode = useClickOutside(() => {
    setOpen(false);
  });
  const print = (e) => {
    e.preventDefault();
    dispatch(
        assignWorkPlanMar(work_plan_id, arrID, navigate, setopenInternet, setLoad)
    );
  };
  return (
    <div
      className={`div_assign_stock ${
        langmiamed == "ar" ? "div_assign_stock_ar" : "div_assign_stock_en"
      } `}
      ref={domNode}
      dir={langmiamed == "ar" ? "rtl" : "ltr"}
    >
      {loadingUnassign_marketing == false ? (
        Unassign_marketing.map((pp) => {
          return (
            <>
              <div className="div_assign_stock_row" key={pp.id}>
                <input
                  type="checkbox"
                  onChange={() => handleCheckboxChange(pp.id)}
                />
                <span
                  style={{
                    color: pp.color || "black",
                    fontFamily: CheckFont(pp.name),
                  }}
                >
                  {pp.name}
                </span>
              </div>
              {showQuantity[pp.id] && ( // عرض حقل الكمية فقط إذا كانت الحالة مُفعلة
                <>
                  <input
                    type="text"
                    className="quantity_stock"
                    placeholder="quantity"
                    onChange={(e) =>
                      handleQuantityChange(pp.id, parseInt(e.target.value, 10))
                    }
                  />
                  {/* <input
                    type="text"
                    className="quantity_stock"
                    placeholder="patch number"
                    onChange={(e) =>
                        handleBatchChange(pp.id,e.target.value)
                      }
                  /> */}
                </>
              )}
            </>
          );
        })
      ) : (
        <div className="div_loadd">
          <InnerLoader />
        </div>
      )}
      <button onClick={print}>{Load ? "Loading ..." : `${t("save")}`}</button>
    </div>
  );
}

export default MultiSelectStock;

import React, { useRef, useState } from "react";
import InnerLoader from "../General/InnerLoader";
import { VariableAssign } from "./VariableAssign";
import { assignMedicalRep } from "../../Redux/Actions/ActionMedicalRep";
import FilterAssign from "./FilterAssign";
import loc from "../../images/icons/location_icon.svg";
import people from "../../images/icon_sidebar/people.png";
import medd from "../../images/icons/med.png";
import { CheckFont } from "../General/GeneralComponent/CheckLang";
import { useSelectorCustom } from "../General/GeneralComponent/useSelectorCustom";
import { FunctionsLoading } from "../Main/Statistics/FunctionsLoading";
import { filterrTargetGroup } from "../../Redux/Actions/ActionTargetGroup";
import { useTranslation } from "react-i18next";
import SelectWithSearch from "../General/GeneralComponent/SelectWithSearch";
import { Table, Form } from "react-bootstrap";

function AssignComponent({
  langmiamed,
  translate,
  target_group,
  loadingTargetGroup,
  dispatch,
  medical_rep_id,
}) {
  const { state, setLoad, handleCheckboxChange, handleSelectAll } =
    VariableAssign();
  const [openFitler, setOpenFilter] = useState(false);
  const assign = () => {
    dispatch(assignMedicalRep(medical_rep_id, state.checkedIds, setLoad));
  };

  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const { state: assginState, handleChangeAssign, setState } = VariableAssign();
  const {
    cities,
    loadingCities,
    specializations,
    loadingspecializations,
    target_types,
    loadingtarget_type,
  } = useSelectorCustom();
  const { LoadSpecializationsCustom, LoadCitiesCustom, LoadTargetTypeCustom } =
    FunctionsLoading();
  const filter = (event) => {
    event.preventDefault();
    setLoading(true);
    dispatch(
      filterrTargetGroup(
        "",
        "",
        assginState.search_value,
        assginState.target_type_id,
        assginState.city_id,
        assginState.specialization_id,
        assginState.medical_rep_name
      )
    );
    setLoading(false);
  };
  const [searchQuery, setSearchQuery] = useState("");
  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value.toLowerCase());
  };

  const filteredTargetGroup = target_group.filter(
    (tt) =>
      tt.name.toLowerCase().includes(searchQuery) ||
      (tt.address && tt.address.toLowerCase().includes(searchQuery)) ||
      (tt.medical_rep_name &&
        tt.medical_rep_name.toLowerCase().includes(searchQuery))
  );

  return (
    <>
      <div className="div_assign ">
        <h5 className={langmiamed == "ar" ? "textRight" : ""}>
          {translate("target_group")}
        </h5>
        <div className="action_assign">
          <span
            className={`btn_assign ${
              langmiamed == "ar" ? "margin_left_10" : "margin_right_10"
            }`}
            onClick={assign}
          >
            {state.Load ? "Loading ..." : `${translate("assign")}`}
          </span>
          <span className="btn_assign" onClick={() => setOpenFilter(true)}>
          {translate("filter")}
          </span>
        </div>
      </div>

      {/* Search Input */}
      <div className="searchBox">
        <input
          type="text"
          placeholder={translate("search")}
          value={searchQuery}
          onChange={handleSearchChange}
          className="view_creatable1"
        />
        <div className="divForSelectAll">
          <div class="checkbox-wrapper-26">
            <input
              onChange={(event) =>
                handleSelectAll(event, "checkedIds", "selectAll", target_group)
              }
              checked={state.selectAll}
              type="checkbox"
              id="_checkbox-26"
            />
            <label for="_checkbox-26">
              <div class="tick_mark"></div>
            </label>
          </div>
          <span>{translate("selectall")}</span>
        </div>
      </div>
      <div className="div_checkbox">
        {loadingTargetGroup == false ? (
          filteredTargetGroup.length > 0 ? (
            filteredTargetGroup.map((tt) => {
              const isChecked =
                state.selectAll || state.checkedIds.includes(tt.id);
              return (
                <div className="name_medRep" key={tt.id}>
                  {/* <input
                    className={
                      langmiamed == "ar" ? "margin_left_20" : "margin_right_20"
                    }
                    type="checkbox"
                    value={tt.id}
                    onChange={(event) =>
                      handleCheckboxChange(event, "checkedIds")
                    }
                    checked={isChecked}
                  /> */}

                  <div class="checkbox-wrapper-26">
                    <input
                      value={tt.id}
                      onChange={(event) =>
                        handleCheckboxChange(event, "checkedIds")
                      }
                      checked={isChecked}
                      type="checkbox"
                      id={tt.id}
                    />
                    <label for={tt.id}>
                      <div class="tick_mark"></div>
                    </label>
                  </div>

                  <div className="colcol">
                    <div className="bothcol">
                      <img src={people} />
                      <span
                        style={{ fontFamily: CheckFont(tt.name) }}
                        className={
                          langmiamed == "ar"
                            ? "margin_right_10"
                            : "margin_left_20"
                        }
                      >
                        {tt.name}
                      </span>
                    </div>
                    {tt.address != null && (
                      <div className="bothcol">
                        <img src={loc} />
                        <span
                          style={{ fontFamily: CheckFont(tt.address) }}
                          className={
                            langmiamed == "ar"
                              ? "margin_right_10"
                              : "margin_left_20"
                          }
                        >
                          {tt.address}
                        </span>
                      </div>
                    )}
                    {tt.medical_rep_name != null && (
                      <div className="bothcol">
                        <img src={medd} />
                        <span
                          style={{ fontFamily: CheckFont(tt.medical_rep_name) }}
                          className={
                            langmiamed == "ar"
                              ? "margin_right_10"
                              : "margin_left_20"
                          }
                        >
                          {tt.medical_rep_name}
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              );
            })
          ) : (
            <div>{translate("no_results_found")}</div>
          )
        ) : (
          <div className="loadSetting">
            <InnerLoader />
          </div>
        )}
      </div>

      {openFitler && (
        <FilterAssign t={translate} open={openFitler} setOpen={setOpenFilter} />
      )}

    </>
  );
}

export default AssignComponent;

// {openFitler && (
//   <FilterAssign t={translate} open={openFitler} setOpen={setOpenFilter} />
// )}

// {/*  */}
// <div className="div_popFilter">
//   <h4>Filter On Target Group</h4>
//   <div className="input_filter_assign">
//     <input
//       style={{ marginLeft: "10px" }}
//       className="view_creatable1"
//       placeholder={` ${t("search")} ...`}
//       onChange={(e) => handleChangeAssign("search_value", e.target.value)}
//     />
//   </div>
//   <div className="input_filter_assign">
//     <SelectWithSearch
//       load={loadingCities}
//       typeCss={true}
//       title={t("cities")}
//       array={cities}
//       setId={(value) => handleChangeAssign("city_id", value)}
//       funb={LoadCitiesCustom}
//     />
//   </div>
//   <div className="input_filter_assign">
//     <SelectWithSearch
//       load={loadingspecializations}
//       typeCss={true}
//       title={t("specializations")}
//       array={specializations}
//       setId={(value) => handleChangeAssign("specialization_id", value)}
//       funb={LoadSpecializationsCustom}
//     />
//   </div>
//   <div className="input_filter_assign">
//     <SelectWithSearch
//       load={loadingtarget_type}
//       typeCss={true}
//       title={t("target_type")}
//       array={target_types}
//       setId={(value) => handleChangeAssign("target_type_id", value)}
//       funb={LoadTargetTypeCustom}
//     />
//   </div>
//   <div className="input_filter_assign">
//     <input
//       style={{ marginLeft: "10px" }}
//       className="view_creatable1"
//       placeholder={` ${t("delegates")} ...`}
//       onChange={(e) =>
//         handleChangeAssign("medical_rep_name", e.target.value)
//       }
//     />
//   </div>
//   <button className="targetfilter_button" onClick={filter}>
//     {loading ? "Loading ..." : `${t("filter")}`}
//     {/* {t('filter')} */}
//   </button>
// </div>
// {/*  */}

import { useState } from "react"
import { CheckFont } from "../General/GeneralComponent/CheckLang";

export const VariableLast = () => {
    const [state, setState] = useState({
        arr: [],
        first_name: "",
        last_name: "",
        last_login_at:"",    
        Load: false,
    })
    const setLoad = (value) => {
        setState((prevState) => ({
            ...prevState,
            Load: value,
        }));
    };
    return {
        ...state,
        state,
        setState,
        setLoad, }
}

export const ColumnLast =(translate,handleEdit,warning)=>{
    const columnsLast = [
        {
            name: <span className="color_spann">{translate('name')}</span>, sortable: true,
            cell: (row) => (
                <span style={{fontFamily: CheckFont(row.first_name)}}>{row['first_name']} {row['last_name']}</span>
            )
        },
        {
            name: <span className="color_spann">{translate('last_login_at')}</span>, sortable: true,
            cell: (row) => (
<span style={{ fontFamily: CheckFont(row.last_login_at) }}>
      {new Date(row['last_login_at']).toLocaleString('en-US', { 
        year: 'numeric', 
        month: 'short', 
        day: 'numeric', 
        hour: '2-digit', 
        minute: '2-digit' 
      })}
    </span>            )
        },
    ]
    return {
        columnsLast
    }
}